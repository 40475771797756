.content-wrapper {
  padding: 0 1.6rem;
  border-bottom: 0.1rem solid #f2f2f2;
}

.form-group label {
  color: #42444a;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-20);
}

.form-group-field input {
  width: 100%;
  height: 2.8rem;
  padding: 0rem !important;
  border: 0.1rem solid #c7c7cc;
  border-radius: 0.3rem;
  margin-top: 0.5rem;
  font-size: var(--main-txt-size-14);
  text-indent: 1rem;
}

.form-group input:focus {
  border: 0.1rem solid #c7c7cc;
  outline: none;
}

.api_loader {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.card-body {
  height: calc(50vh - 170px);
  overflow-y: auto;
}

.form-group-field p {
  color: #95969d;
  font-size: var(--main-txt-size-12);
}

.list-of-rid {
  color: #2f80ed !important;
  font-size: 12px;
}

.list-of-rid-div {
  cursor: pointer;
}

.file-contraints-div img {
  margin-left: 0.4rem;
  margin-top: 0.2rem;
  cursor: pointer;
}

.btn-toggle {
  display: flex;
  padding: 0.6rem 7.4rem 0.7rem 7.4rem;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  border-radius: 0.4rem;
  margin: 1rem 0.8rem 0.8rem 0.8rem;
  cursor: pointer;
  color: var(--main-txt-green) !important;
  font-size: var(--main-txt-size-14);
  white-space: nowrap;
}

.btn-toggle:hover {
  background: linear-gradient(
      0deg,
      rgba(61, 178, 103, 0.15) 0%,
      rgba(61, 178, 103, 0.15) 100%
    ),
    #fff;
}

.btn-toggle label {
  text-align: center;
  font-size: var(--main-txt-size-14);
  background: none;
  padding: 0rem;
  cursor: pointer;
  margin: 0.5rem;
}

.btn-toggle input {
  display: none;
}

.btn-toggle label img {
  cursor: pointer;
}

.btn-toggle.error {
  border-radius: 0.4rem;
  border: 0.1rem solid var(--red, #eb5757);
}

.card {
  background-color: #fff;
  border-bottom: 0.1rem solid #f2f2f2;
  overflow: hidden;
}

.card-header {
  padding: 1rem 1.2rem;
}

.title {
  color: black;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  padding: 0.2rem 1.4rem;
  color: #2d9cdb;
}

.checkbox-container input {
  cursor: pointer;
  margin-left: 0.7rem;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-14);
}

.checkbox-container-hovered {
  background: linear-gradient(
      0deg,
      rgba(61, 178, 103, 0.1) 0%,
      rgba(61, 178, 103, 0.1) 100%
    ),
    #fff;
  padding: 0.2rem 1.4rem;
}

.filename {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: inherit;
  white-space: nowrap;
}

.list-container {
  padding: 0.5rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.no-aoi-message {
  color: var(--sat-sure-grey-sat-sure-grey-2, #696b72);
  font-size: var(--main-txt-size-12);
  padding: 0.8rem 1.6rem 1.6rem 1.6rem;
}

.error-message {
  color: var(--syntax-red, #eb5757);
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-500);
  letter-spacing: 0.01rem;
}

.file-constraints {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
}

.file-constraints div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
}

.hover-icons {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  justify-content: flex-end;
}

.hover-icons img {
  padding: 0rem 0.5rem;
}

.request-modal-container {
  height: 22.6rem;
  border-radius: 0.8rem;
  background: #fff;
  box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.request-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 1.1rem 0.8rem 1rem 1.6rem;
}

.request-modal-header p {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.request-modal-header img {
  width: 2.2rem;
  height: 2.2rem;
  flex-shrink: 0;
  cursor: pointer;
}

.request-body {
  padding: 2.4rem 6.1rem 3.3rem 6.1rem;
}

.request-body span {
  display: flex;
  justify-content: center;
  padding-bottom: 1.6rem;
}

.request-name {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-14);
}

.upload-img {
  width: 1.6rem;
  height: 1.6rem;
}

.common-modal-btn {
  display: flex;
  justify-content: center;
  padding-top: 2.1rem;
}

.color {
  display: inline-flex;
  padding: 0.7rem 2.3rem 0.6rem 2.3rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
}

.start-button {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  background: #3db267;
  color: var(--main-txt-white-color);
  padding: 0.7rem 2.2rem 0.6rem 2.2rem;
}

.start-button.margin-right {
  margin-right: 2rem;
}

.projects-btn {
  border: 0.1rem solid #3db267;
  color: var(--main-txt-green);
  background: #fff;
  padding: 0.7rem 1.2rem 0.7rem 1.2rem;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.members__control--is-focused {
  box-shadow: none !important;
  outline: none !important;
  font-size: var(--main-line-height-14);
  border-color: var(--main-txt-green) !important;
}

.members__value-container {
  height: 3.4rem;
}

.members__indicator {
  color: var(--main-txt-black-color) !important;
}

.members__placeholder {
  color: var(--main-txt--sat-lite-sure-grey) !important;
  font-size: var(--main-line-height-14);
}

.region-selection {
  position: relative;
  display: flex;
  flex-direction: column;
}

.region-level-container {
  width: 90%;
}

.region-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60%;
  display: inline-block;
}

.data-product .css-b62m3t-container {
  height: 3.7rem;
}

.members__input-container {
  margin: 0rem !important;
  padding: 0rem !important;
}

.members__option {
  height: 3.2rem;
  font-size: var(--main-txt-size-14) !important;
}

.members__option--is-focused {
  background-color: var(--main-txt-green) !important;
  color: var(--main-txt-white-color) !important;
}

.members__option--is-focused .region-level {
  color: var(--main-txt-white-color) !important;
}

.members__option--is-selected {
  background-color: var(--main-txt-green) !important;
  color: var(--main-txt-white-color) !important;
}

.members__option--is-selected .region-level {
  color: var(--main-txt-white-color) !important;
}

.members__indicators {
  display: none !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 9999;
}

.marking-aoi-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 3.6rem 1.3rem 1.6rem;
  border-bottom: 0.1rem solid #f2f2f2;
}

.marking-aoi-back {
  display: flex;
  align-items: center;
}

.marking-aoi-back label {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  margin-right: 0.8rem;
  cursor: pointer;
}

.marking-aoi-back label img {
  width: 100%;
  height: 100%;
}

.marking-aoi-back span {
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.aoi-save-btn {
  cursor: pointer;
  color: var(--main-txt-green);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
}

.marking-aoi-content {
  padding: 1.6rem;
}

.marking-aoi-para {
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-12);
}

.file-constraints-tooltip {
  display: flex;
  position: absolute;
  left: 100%;
  visibility: hidden;
}

.right-triangle {
  width: 0;
  height: 0;
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-right: 1rem solid rgba(0, 0, 0, 0.8);
  margin-top: 2%;
}

.file-constraints-content {
  width: 22.1rem;
  height: auto;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.4rem;
  padding: 0.8rem;
}

.file-constraints-content .label,
.file-constraints-content .value {
  color: #ebebeb;
  font-size: var(--main-txt-size-12);
  white-space: break-spaces;
}

.file-constraints-content ul {
  width: 100%;
}

.file-constraints-content li {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.2rem;
}

.label {
  flex-basis: 35%;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.8rem;
}

.value {
  width: 60%;
}

.file-icon:hover + .file-constraints-tooltip {
  visibility: visible;
  transition: 0.5s;
  transition-delay: 0.1s;
}

.custom-select-option {
  display: flex;
  justify-content: space-between;
}

.custom-select-option span {
  white-space: break-spaces;
}
