@import '../../assets/styles/modules/mixins.scss';

.setting_container {
  @include common-setting-wrapper;

  .password_details {
    @include center-space-between;

    .password_edit {
      flex-basis: 60%;
      border: 0.1rem solid var(--main-border-lite-grey-color);
      border-radius: 0.4rem;
      padding: 6rem;

      .passowrd_order_sections {
        .password_list {
          @include center-space-between;
          padding: 2.4rem 0rem;

          &:not(:last-child) {
            border-bottom: 0.1rem solid var(--main-border-lite-grey-color);
          }

          label {
            @include font-profile-14-semibold;
            color: var(--main-txt-zambezi-color);
          }

          span {
            @include direction_column;
            align-items: flex-end;
            position: relative;
            width: 50%;

            .password_input {
              @include input-text;
              @include dimensions(100%, 3.6rem);
            }
          }

          .password_hide_show {
            cursor: pointer;
            position: absolute;
            inset: 0.8rem 1rem auto auto;
            @include dimensions(2rem, 2rem);

            img {
              width: 100%;
              height: 100%;
            }
          }

          .invalid_password {
            @include error_text;
          }
        }
      }

      .update_btn {
        @include center;
        margin-top: 4rem;

        .update {
          @include common-btn;
          background-color: var(--main-bg-green-color);
          color: var(--main-txt-white-color);
          @include font-profile-16-medium;
        }
      }
    }

    .password_desc_section {
      flex-basis: 40%;
      padding: 2rem;

      h1 {
        @include font-profile-20-semibold;
        color: var(--main-txt-dark-charcoal-color);
      }

      .passowrd_order_list {
        padding: 2rem;

        li {
          @include font-profile-16-medium;
          list-style-type: disc;
          color: var(--main-txt-zambezi-color);

          &:not(:last-child) {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
