@import '../../assets/styles/modules/mixins.scss';

.header_wrapper {
  @include align-center;
  justify-content: space-between;
  background-color: var(--main-bg-white-color);
  border-bottom: 0.1rem solid #f2f2f2;
  box-shadow: 0rem 0.1rem 1rem rgba(92, 92, 92, 0.07);
  padding: 0rem 2rem;
  height: 6rem;

  .common_logo_sections {
    flex-basis: 33.3%;

    .brand_logo {
      @include dimensions(9.3rem, 3.2rem);
      padding-left: 2rem;
      cursor: pointer;

      img {
        @include dimensions(100%, 100%);
      }
    }

    &.profile_icon {
      @include align-center;
      justify-content: flex-end;

      .profile_list_item {
        position: relative;
        padding: 1rem 0.5rem;

        &:not(:last-child) {
          margin-right: 0.7rem;
          padding: 0;
        }

        .profile_icons {
          cursor: pointer;
          display: flex;
          padding: 1.2rem 1.3rem;

          &:hover {
            background-color: rgba(0, 0, 0, 0.09);
            border-radius: 50%;

            & ~ .profile_tool_tip {
              display: flex;
            }
          }
        }

        .profile_tool_tip {
          background: rgba(0, 0, 0, 0.5);
          border-radius: 0.4rem;
          position: absolute;
          bottom: -3.5rem;
          display: none;
          padding: 0.5rem 0.8rem;
          z-index: 9;

          p {
            @include font-profile-12-medium;
            color: var(--main-txt-white-color);
            margin: 0.2rem 0rem;
          }
        }

        .profile_dropdown_menu {
          display: block;
          position: absolute;
          right: 0rem;
          z-index: 11;
          min-width: 8rem;
          background: var(--main-bg-white-color);
          padding: 1rem 0rem;
          box-shadow: 0.4rem 0.4rem 2rem rgba(0, 0, 0, 0.25);
          border-radius: 0.8rem;

          .profile_list {
            @include align-center;
            cursor: pointer;
            height: 3.2rem;
            padding: 0rem 2rem;

            label {
              display: block;
              @include dimensions(2rem, 2rem);
              margin-right: 0.8rem;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            span {
              @include font-profile-12-semibold;
              display: block;
              color: var(--main-txt-mid-grey-color);
            }
          }
        }
      }
    }
  }
}

.discard_header_modal {
  @include space-between;
  padding: 0rem 1rem 1rem;
  border-bottom: 0.1rem solid var(--main-border-white-lite-color);

  p {
    @include font-profile-14-medium;
    color: var(--main-txt-dark-grey);
  }

  span {
    display: flex;
    cursor: pointer;
  }
}

.discard_modal_content {
  padding: 1.6rem;
  border-bottom: 0.1rem solid var(--main-border-white-lite-color);

  p {
    @include font-profile-14-medium;
    color: var(--main-txt-dark-grey);
  }
}

.discard_modal_footer {
  @include justify-end;
  padding: 1.6rem;

  .discard_btn {
    display: flex;
    height: 3.2rem;
    padding: 0.7rem 1.6rem 0.6rem 1.6rem;
    margin-right: 1rem;
    cursor: pointer;
  }

  .btn_cancel_discard {
    @include font-profile-14-medium;
    color: var(--main-txt-green);
    background: var(--main-bg-white-color);
  }

  .btn_remove_discard {
    @include font-profile-14-medium;
    color: var(--main-txt-white-color);
    background: var(--main-bg-green);
  }
}
