.project-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid #f2f2f2;
  height: 19.2rem;
}

.left-div {
  border-right: 0.1rem solid #f2f2f2;
  flex-basis: 55%;
  overflow-y: auto;
  height: 160px;
}

.left-div::-webkit-scrollbar {
  width: 0.3rem;
}

.left-div::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.left-div::-webkit-scrollbar-thumb {
  background: #c7c7cc;
  border-radius: 0.5rem;
}

.left-div::-webkit-scrollbar-thumb:hover {
  background: #c7c7cc;
}

.right-div {
  /* flex: 1;*/
  flex-basis: 45%;
  overflow: auto;
}

.project-left-header {
  display: flex;
  justify-content: flex-start;
  margin-left: 3rem;
  position: sticky;
  top: 0;
  padding: 1rem;
  z-index: 1;
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  background: #fff;
}

.project-date-item {
  display: flex;
  height: 2.5rem;
  padding: 1rem 35.7rem 1.9rem 1.5rem;
  align-items: flex-end;
  gap: 0.9rem;
  border-radius: 0.4rem;
  background: #f8f8f8;
  margin: 0.8rem 4rem;
}

.date-icon {
  width: 1.6rem;
  height: 1.6rem;
}

.tree-aoi-details {
  padding: 1rem 5.6rem;
}

.tree-aoi-details li {
  display: flex;
  position: relative;
}

.tree-aoi-details li:not(:first-child) {
  align-items: center;
}

.tree-aoi-details h2 {
  right: 0rem;
  bottom: -1.7rem;
}

.tree-aoi-details li:nth-child(-n + 2) {
  margin-bottom: 1.6rem;
}

.tree-aoi-details li:nth-child(3) {
  margin-bottom: 3rem;
}

.tree-aoi-head {
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  flex-basis: 50%;
}

.tree-aoi-head-value {
  color: var(--sat-sure-grey-sat-sure-grey-2, #696b72);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  flex-basis: 50%;
  padding-left: 0px !important;
}

.region-dates-container {
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f8f8f8;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.region-dates-container.active {
  background: linear-gradient(
      0deg,
      rgba(61, 178, 103, 0.1) 0%,
      rgba(61, 178, 103, 0.1) 100%
    ),
    #fff;
}
.region-dates-container:hover {
  background: linear-gradient(
      0deg,
      rgba(61, 178, 103, 0.1) 0%,
      rgba(61, 178, 103, 0.1) 100%
    ),
    #fff;
}

.date-section {
  padding: 0.8rem 3.5rem;
  height: calc(100vh - 270px);
  overflow-y: auto;
}

.aoi-item {
  color: var(--sat-sure-grey-sat-sure-grey-2, #696b72);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  padding-left: 1rem;
}

.region-dates-container span {
  padding-top: 0.225rem;
}

.expand-arrow {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.region-expand-collapse {
  display: flex;
  background: #f9f9f9;
  height: 34px;
  align-items: center;
  cursor: pointer;
  padding-left: 3rem;
}
.expand-arrow img {
  width: 100%;
  height: 100%;
}
.region-title-wrapper {
  display: flex;
  flex-basis: 95%;
  cursor: pointer;
}
.title-left {
  width: 60%;
  cursor: pointer;
}

.title-left h2 {
  padding-left: 15px;
}

.title-right {
  width: 35%;
  cursor: pointer;
}

.table-wrapper {
  display: flex;
}
.region-table {
  width: 100%;
}

.region-table-row,
.region-body td {
  background: transparent;
  cursor: pointer;
  height: 4.6rem;
  vertical-align: middle;
  text-wrap: nowrap;
}

.region-head {
  background: #fff;
  z-index: 9;
  width: calc(100% - 1rem);
}

/* .region-body {
  height: calc(100vh - 26rem);

  overflow-y: auto;
  display: block;
} */

.region-head tr,
.region-body tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.region-head .head-first {
  width: 40%;
  text-align: left;
  padding-top: 10px;
}
.region-first-body span {
  margin-right: 20px;
}

.region-first-body {
  width: 40%;
  text-align: left;
}

.region-first-body:first-child,
.head-first:first-child {
  padding-left: 40px;
}
.checkbox-body,
.checkbox-head {
  width: 10%;
  cursor: pointer;
}
.checkbox-body input[type='checkbox'] {
  cursor: pointer;
}

.region-body tr {
  border-collapse: collapse;
  border: none;
}

.region-body tr:hover {
  background-color: #3db9161a;
}

.work-flow-header {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  padding-left: 10px;
}

.multiselect-assignees {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  height: 29.6rem;
  background: white;
  position: absolute;
  top: 11rem;
  right: 0px;
  box-shadow: -2px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.assign-title-wrapper {
  height: 34px;
  align-items: center;
  display: flex;
  padding: 0px 20px;
  border-bottom: 1px solid #f2f2f2;
}
.assign-bottom {
  padding: 16px 20px;
  align-self: flex-end;
  border-top: 1px solid #f2f2f2;
}

.assign-btn-save {
  width: 64px;
  height: 32px;
  background: #3db267;
  color: #fff;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}
.assign-btn-cancel {
  background: transparent;
  color: #3db267;
  cursor: pointer;
}
.assign-admin {
  padding: 0px 20px;
  width: 70%;
}
.assign-admin li {
  display: flex;
  margin-bottom: 1.6rem;
  align-items: center;
}
.select-all-wrapper {
  display: flex;
  height: 36px;
  align-items: center;
}
.select-all-wrapper label {
  width: 10%;
  display: flex;
  justify-content: center;
}
.selected-text {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
}
.select-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}
.unassigned-wrapper {
  height: 3.2rem;
  width: 3.2rem;
}
.unassigned-wrapper img {
  width: 100%;
  height: 100%;
}
