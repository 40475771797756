.delete-aoi-modal-content {
  display: flex;
  flex-direction: row;
}

.delete-aoi-modal-body {
  background: white;
  border-radius: 0.9rem;
}

.delete-aoi-modal-title {
  color: #42444a;
  font-size: var(--main-txt-size-14);
  padding: 2.4rem 1.6rem;
}

.aoi-name-text {
  font-weight: var(--main-font-weight-600);
  color: #42444a;
  font-size: var(--main-txt-size-14);
}

.delete-aoi-modal {
  border-top: 0.1rem solid #f2f2f2;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cancel-delete-modal-button {
  height: 3.2rem;
  width: 7.8rem;
  color: #95969d;
  margin-right: 0.8rem;
}

.btn.delete-aoi-modal-button {
  height: 3.2rem;
  background-color: #eb5757;
  width: 7.8rem;
  color: var(--main-txt-white-color);
}

.triangle {
  width: 0rem;
  height: 0rem;
  border: 0.8rem solid transparent;
  border-right: 0.8rem solid white;
  margin: 3.9rem auto;
}
.bottom-triangle {
  width: 0rem;
  height: 0rem;
  border: 0.8rem solid transparent;
  border-right: 0.8rem solid white;
  margin-top: auto;
  margin-bottom: 1rem;
}
