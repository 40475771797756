.modal-header {
  padding-top: 0.688rem;
  padding-left: 1.5rem;
  padding-bottom: 0.688rem;
  padding-right: 0.688rem;
  border-bottom: 0.1rem solid var(--main-border-grey-color);
  display: flex;
  justify-content: space-between;
}

.modal-header p {
  font-size: var(--main-txt-size-16);
  color: var(--main-txt-dark-grey);
  font-weight: var(--main-font-weight-600);
}

.modal-header img {
  cursor: pointer;
}

.modal-body {
  padding: 1.5rem;
  overflow-y: auto;
  max-height: calc(100vh - 180px);
  overflow-x: hidden;
}

.input-box {
  border: 0.1rem solid var(--main-border-grey-color);
  height: 3.2rem;
  border-radius: 0.4rem;
  padding-left: 1rem;
}

.error-input {
  border: 0.1rem solid red;
  height: 3.2rem;
  border-radius: 0.4rem;
  padding-left: 1rem;
}

.project-exists-label {
  font-size: var(--main-txt-size-10);
  font-style: italic;
  font-weight: var(--main-font-weight-400);
  color: red;
}

.project-name-sections input:focus-visible {
  outline: none !important;
}

.project-name-sections p {
  color: red;
}

.project-description-sections textarea {
  border: 0.1rem solid var(--main-border-grey-color);
  border-radius: 0.4rem;
  padding-left: 1rem;
  padding-top: 0.8rem;
  resize: none;
}

.project-description-sections textarea:focus-visible {
  outline: none !important;
}

.project-name-sections,
.project-description-sections {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.4rem;
}

.owner-div {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

.owner-div img {
  height: 50%;
  width: 50%;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.owner-div img:hover + .owner-desc {
  visibility: visible;
}

.owner-desc {
  width: 13.2rem;
  position: absolute;
  z-index: 1;
  right: 1%;
  margin-top: 2rem;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.8rem;
  border-radius: 4px;
  visibility: hidden;
}

.owner-desc p {
  color: white;
}

.owner-details {
  padding: 0 2.9rem 2.1rem 1rem !important;
}

.project-name-sections label,
.project-description-sections label {
  font-size: var(--main-txt-size-14);
  color: var(--main-txt-sure-grey);
  font-weight: var(--main-font-weight-500);
  padding-bottom: 0.8rem;
}

.members-label {
  font-size: var(--main-txt-size-14);
  color: var(--main-txt-dark-grey);
  font-weight: var(--main-font-weight-600);
  padding-bottom: 0.8rem;
}

.added-members {
  width: 93%;
  overflow: auto;
  padding: 0.75rem;
  max-height: 13.2rem;
  margin-bottom: 1rem;
}

.remove-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding: 4px;
}

.remove-icon:hover {
  border-radius: 2px;
  background: #f9f9f9;
}

.remove-icon img {
  width: 100%;
  height: 100%;
}

.added-members::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

.added-members::-webkit-scrollbar-track {
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.1);
}

.added-members::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.2);
}

.added-members::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.added-members::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.individual-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.avatar-div {
  display: flex;
}

.sb-avatar .sb-avatar--text {
  padding-top: 0.3rem;
}

.individual-member-details {
  display: flex;
  flex-direction: column;
  margin-left: 0.7rem;
}

.member-name {
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  margin-bottom: 0.1rem;
}

.member-email,
.owner-label {
  color: var(--main-txt-sure-grey);
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-500);
}

.disclaimer {
  padding-top: 0.75rem;
}

.disclaimer p {
  color: rgba(0, 0, 0, 0.8);
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}

.create-btn-div {
  display: flex;
  padding: 1.6rem;
  justify-content: flex-end;
  border-top: 1px solid #f2f2f2;
}

.create-btn {
  /* height: 3.2rem; */
  padding: 0.8rem;
  color: white;
  background: var(--main-txt-green);
  border-radius: 0.4rem;
  cursor: pointer;
}

.create-btn.create-project-button {
  width: 12rem;
}

.react-select__control {
  margin-bottom: 0rem;
  border: 0.1rem solid #e0e0e0 !important;
  border-radius: 0.8rem;
  height: 3.5rem;
}

.members__control--is-focused {
  box-shadow: none !important;
  outline: none !important;
  font-size: var(--main-line-height-14);
  border-color: var(--main-txt-green) !important;
}

.members__indicator {
  color: var(--main-txt-black-color) !important;
}

.members__placeholder {
  color: var(--main-txt--sat-lite-sure-grey) !important;
  font-size: var(--main-line-height-14);
}

.input-div .css-b62m3t-container {
  height: 3.7rem;
}

.members__option {
  height: 3.2rem;
  font-size: 1.4rem !important;
}

.members__option--is-focused {
  background-color: var(--main-txt-green) !important;
  color: var(--main-txt-white-color) !important;
}

.members__option--is-selected {
  background-color: var(--main-txt-green) !important;
  color: var(--main-txt-white-color) !important;
}

.members__indicators {
  display: none !important;
}

.members__value-container--has-value {
  font-size: var(--main-line-height-14);
}

.avatar-placeholder {
  display: flex;
}

.avatar-placeholder p {
  color: #95969d;
  margin-left: 0.5rem;
}

.upload-project {
  display: flex;
  padding: 6px 0px 7px 0px;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  border-radius: 4px;
  background: #f9f9f9;
  cursor: pointer;
  margin-top: 0.8rem;
}

.disabled-upload-project {
  cursor: not-allowed;
}

.disabled-upload-project label {
  pointer-events: none;
}

.project-upload-sections label {
  font-size: var(--main-txt-size-14);
  padding-bottom: 0.2rem;
}

.upload-project button {
  color: var(--Sparta-Sparta-Dark-1, #3db267);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #f9f9f9;
  cursor: pointer;
}

.upload-project img {
  padding-bottom: 3px;
}

.uploaded-files ul {
  display: flex;
  flex-wrap: wrap;
}

.uploaded-files img {
  cursor: pointer;
}

.uploaded-files li {
  display: inline-flex;
  height: auto;
  max-width: 80%;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Sparta-Sparta-Dark-1, #3db267);
  color: #3db267;
  margin: 0.8rem 0.8rem 0.8rem 0rem;
  font-size: 14px;
}
.create-step-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
}

.steps {
  font-size: var(--main-txt-size-14);
  color: var(--main-txt-dark-grey);
}

.create-step {
  display: flex;
  align-items: center;
}

.react-datepicker__input-container .date-picker {
  border: 0.1rem solid var(--main-border-grey-color);
  height: 3.2rem;
  border-radius: 0.4rem;
  padding-left: 1rem;
}
.project-due-date-box {
  height: 3.2rem;
  width: 50%;
  padding-left: 0.5rem;
  border: 0.1rem solid var(--main-border-grey-color) !important;
}
.create-due-date {
  border: 0.1rem solid var(--main-border-grey-color) !important;
  border-radius: 0.4rem;
  padding: 0.5rem !important;
}

.disabled-due-date,
.disabled-due-date input {
  background-color: #f2f2f2 !important;
  cursor: not-allowed;
}

.request-due-date-popper {
  transform: translate(7.4rem, 5.4rem) !important;
}
.project-due-date-popper {
  transform: translate(7.4rem, 21.9rem) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  transform: translate(1019.5px, 46.5px);
}

.custom-date-input input {
  border: 0;
}

.project-select__control--is-disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.project-select__control--is-disabled .project-select__multi-value__remove {
  pointer-events: none !important;
}

.project-select__menu-list {
  max-height: 14rem !important;
}

.error-border {
  border: 1px solid red;
}

/* for the multi member select */
.multi-select-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem 0.2rem 0.5rem;
  border: 0.1rem solid var(--main-border-white-smoke-color);
  margin: 0.5rem 1rem 0.5rem 0;
}
.multi-select-value span {
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}
.multi-select-value button {
  border: none;
  background: none;
  cursor: pointer;
  color: var(--main-txt-dark-grey);
  margin-left: 1rem;
}
.member-option-avatar {
  margin-right: 0.5rem;
  background-color: var(--main-border-whisper-color) !important;
  border: none !important;
  width: 2.2rem !important;
  padding: 0.2rem !important;
}
