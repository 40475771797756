.request-info {
  padding: 1rem 2rem 2rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.1rem solid #f2f2f2;
}

.request-info-1 {
  width: 40%;
}

.request-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.request-info-header label {
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
}

.request-info-header p {
  color: var(--main-txt--sat-lite-sure-grey);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.scroll-button.left {
  border-right: 0.1rem solid #f2f2f2;
}

.scroll-button.right.disabled,
.scroll-button.left.disabled {
  display: none;
}

.scroll-button.right {
  border-left: 0.1rem solid #f2f2f2;
}

.satellite-data-container {
  height: 13rem;
}

.satellite-data-header {
  font-size: 16px;
  font-weight: 500;
  color: #42444a;
}
