@import '../../assets/styles/modules/mixins.scss';

.catalog_wrapper {
  height: calc(100vh - 6.1rem);
  background-color: var(--main-bg-white-brow-color);

  .catalog_sections {
    @include dimensions(100%, 100%);
    display: flex;

    .catalog_container {
      @include dimensions(100%, 100%);
      display: flex;
      background: var(--main-bg-white-color);

      .top_heading {
        @include dimensions(100%, auto);
        overflow: hidden;

        .header {
          @include font-profile-16-semibold;
          padding: 1.2rem 5.6rem;
          color: var(--main-txt-dark-grey);
          border-bottom: 0.1rem solid var(--main-border-white-lite-color);
        }

        .catalog_toggle {
          .toggle_tab_list {
            display: flex;
            margin-top: 1rem;

            .module_tab {
              @include font-profile-14-medium;
              padding: 0.4rem 3.6rem;
              cursor: pointer;
              color: var(--main-txt-sure-grey);
              text-wrap: nowrap;

              &:focus,
              &[aria-selected='true'] {
                color: var(--main-txt-green);
                border-bottom: 0.1rem solid var(--main-border-green);
              }
            }
          }

          .data_panel_call {
            .data_product_wrapper {
              .data_product_header {
                padding: 1.5rem;
                @include align-center;

                label {
                  @include font-profile-24-semibold;
                  margin-left: 1.7rem;
                  color: var(--main-txt-dark-grey);
                }

                span {
                  display: block;
                  @include dimensions(2.4rem, 2.4rem);
                  cursor: pointer;

                  img {
                    @include dimensions(100%, 100%);
                  }
                }
              }

              .data_product_body {
                padding: 0rem 5.4rem;
                overflow-y: scroll;
                height: calc(100vh - 24rem);

                .data_product_description {
                  margin-bottom: 2.1rem;

                  h6 {
                    @include font-profile-16-semibold;
                    color: var(--main-txt-green);
                    padding-bottom: 1.2rem;
                  }

                  p {
                    @include font-profile-16-medium;
                    color: var(--main-txt-dark-grey);
                    text-align: justify;
                    text-indent: 8rem;
                    line-height: 2.7rem;
                  }
                }

                table {
                  width: 100%;
                  border-radius: 0.4rem;
                  border: 0.1rem solid var(--main-txt-white-grey-color);

                  thead {
                    tr {
                      background: var(--main-bg-light-grey-color);
                    }
                  }

                  th {
                    @include font-profile-16-semibold;
                    padding: 1.6rem 0;
                    color: var(--main-txt-green);
                  }

                  .width_15 {
                    width: 15%;
                  }

                  .width_70 {
                    width: 70%;
                  }

                  .text_align_left {
                    text-align: left;
                    padding-left: 3rem;
                  }

                  td {
                    @include text-align-center;
                    @include font-profile-16-medium;
                    padding: 2.9rem 0;
                    color: var(--main-txt-dark-grey);
                    background: none;
                    border-right-style: dashed;
                    border-right-width: 0.1rem;
                    border-right-color: var(--main-border-white-grey-color);
                    border-bottom-style: dashed;
                    border-bottom-width: 0.1rem;
                    border-bottom-color: var(--main-border-white-grey-color);
                  }

                  tr:last-child td {
                    border-bottom-style: none;
                  }

                  td:last-child {
                    border-right-style: none;
                  }
                }

                .notes {
                  padding: 1.2rem 0 2.4rem 0;
                  color: var(--main-txt-dark-grey);
                  @include font-profile-14-semibold;
                }

                .data_product_tags {
                  padding: 3.2rem 0;

                  h6 {
                    @include font-profile-16-semibold;
                    color: var(--main-txt-green);
                  }

                  .tag_list {
                    @include direction_row;
                    padding-top: 2rem;

                    li {
                      @include font-profile-14-medium;
                      padding: 0.4rem 0.8rem;
                      border-radius: 0.4rem;
                      background: var(--main-bg-white-smoke-grey-color);
                      color: var(--main-txt-dark-grey);

                      &:not(:last-child) {
                        margin-right: 1.6rem;
                      }
                    }
                  }
                }

                .data_product_output {
                  h6 {
                    @include font-profile-16-semibold;
                    color: var(--main-txt-green);
                    padding-bottom: 1.2rem;
                  }

                  .map_continer {
                    display: flex;
                    width: 100%;
                    border-radius: 0.4rem;
                    border: 0.1rem solid var(--main-txt-white-grey-color);
                    background: var(--main-bg-white-color);

                    .data_product_map_wrapper {
                      @include dimensions(100%, 100%);

                      .data_product_map {
                        @include dimensions(100%, 40rem);
                        position: relative;

                        .map_view {
                          background: var(--main-bg-satsure-black-grey);
                          border-radius: 0.4rem;
                          display: flex;
                          padding: 0.8rem 2.2rem;
                          position: absolute;
                          right: 2.4rem;
                          top: 1.6rem;
                          z-index: 1;
                          cursor: pointer;

                          li {
                            color: var(--main-txt-white-color);

                            &:first-child {
                              margin-right: 3rem;
                            }
                          }
                        }

                        .map_view_locations {
                          position: absolute;
                          left: 1.5rem;
                          top: 1.3rem;
                          z-index: 1;
                          background: var(--main-bg-satsure-black-grey);
                          padding: 0.6rem 2rem;
                          @include align-center;
                          border-radius: 0.4rem;

                          p {
                            color: var(--main-txt-white-color);
                            @include font-profile-10-medium;
                          }

                          span {
                            padding-right: 0.5rem;
                            @include dimensions(2rem, 2rem);

                            img {
                              @include dimensions(100%, 100%);
                            }
                          }
                        }

                        .map_controls_list {
                          position: absolute;
                          top: 50%;
                          transform: translate(0%, -50%);
                          right: 0.8rem;
                          z-index: 2;
                        }

                        .map_control_legend {
                          border-radius: 0.4rem;
                          display: flex;
                          position: absolute;
                          right: 1.6rem;
                          bottom: 1.6rem;
                          z-index: 1;
                          background: rgba(37, 37, 37, 0.75);
                        }
                      }

                      .date_container {
                        @include center-space-between;
                        margin: 2rem;

                        .date_list {
                          flex-basis: 62%;
                          @include justify-end;

                          li {
                            &:not(:last-child) {
                              margin-right: 5rem;
                            }

                            .mapdate_available {
                              @include common-btn;
                              border: 0.1rem solid var(--main-border-lite-grey);
                              color: var(--main-txt-lite-grey);

                              &.activeDateButton {
                                border: 0.1rem solid var(--main-border-green);
                                color: var(--main-txt-green);
                              }
                            }
                          }
                        }

                        .download_btn {
                          @include common-btn;
                          padding: 0.5rem 2.4rem 0.6rem 2.3rem;
                          background: var(--main-border-green);
                          color: var(--main-txt-white-color);
                        }
                      }
                    }
                  }
                }

                .api_access_wrapper {
                  padding: 3.2rem 0;

                  h6 {
                    color: var(--main-txt-green);
                    @include font-profile-16-semibold;
                  }

                  .api_access_body {
                    @include space-between;
                    padding-top: 1.2rem;

                    .api_access_response {
                      flex-basis: 48%;
                      border-radius: 0.8rem;
                      border: 0.1rem solid var(--main-border-white-grey-color);

                      .response_header {
                        @include space-between;
                        padding: 0.9rem 2rem;
                        border-bottom: 0.1rem solid
                          var(--main-border-white-grey-color);

                        .response_header_content {
                          color: var(--main-txt-dark-charcoal-color);
                          @include font-profile-14-semibold;
                        }

                        p {
                          @include font-profile-12-semibold;
                          color: var(--main-txt-green);
                          cursor: pointer;
                        }
                      }

                      .response_body {
                        padding: 1rem 2rem;
                        max-height: 20rem;
                        overflow: auto;

                        pre {
                          white-space: break-spaces;
                          word-break: break-all;
                          @include font-profile-14-normal;
                          color: var(--main-txt-grey-color);

                          code {
                            white-space: pre-line;
                          }
                        }
                      }
                    }
                  }
                }

                .usecase_card_wrapper {
                  h6 {
                    margin-bottom: 1.2rem;
                    @include font-profile-16-semibold;
                    color: var(--main-txt-green);
                  }

                  .usecase_wrapper {
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
