.treeview-sections {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.project-tree-list
  .treeview-sections:last-child
  .tree-wrapper
  .vertical-line.product-expand,
.project-tree-list
  .treeview-sections:last-child
  .tree-wrapper
  .vertical-line.request-expand {
  height: 2.1rem;
}

.child-tree.quantity-child-expand
  .date-wrapper:last-child
  .vertical-line.date-list {
  height: 2.2rem;
}

.child-tree.product-child-expand
  .project-tree-list
  .treeview-sections:last-child
  .tree-wrapper
  .vertical-line.quantity-expand {
  height: 2.1rem;
}

.tree-wrapper {
  display: flex;
  padding-top: 0.8rem;
  align-items: center;
}

.horizontal-line {
  display: none;
  width: 2.4rem;
  height: 0.07rem;
  background-color: #c7c7cc;
}

.project-expand {
  /* display: block; */
}

.request-expand {
  display: block !important;
}

.product-expand {
  display: block !important;
}

.quantity-expand {
  display: block !important;
}

.arrow-icon {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  flex-shrink: 0;
}

.tree-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 0.6rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 0.4rem;
  cursor: pointer;
}

.tree-background {
  background-color: rgba(194, 235, 202, 0.4);
}

.tree-status-icon {
  width: 1.6rem;
  height: 1.6rem;
  flex-shrink: 0;
  cursor: pointer;
}

.tree-title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 0.8rem;
}

.tree-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #4f4f4f;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  flex: 1;
  margin-right: 0.5rem;
  width: 0rem;
}

.disabled-tree-title {
  color: var(--main-txt-suva-grey-color);
  cursor: not-allowed;
}

.tree-progress {
  text-overflow: ellipsis;
  overflow: hidden;
  color: #06776c;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.child-tree {
  position: relative;
  margin-left: 1.2rem;
}

.status-icon {
  width: 1.6rem;
  height: 1.6em;
}

.status-icon img {
  width: 100%;
  height: 100%;
}

.project-child-expand {
  /* display: block; */
}

.request-child-expand {
  margin-left: 3rem;
}

.product-child-expand {
  margin-left: 3rem;
}

.quantity-child-expand {
  margin-left: 3rem;
}

.vertical-line {
  display: none;
  position: absolute;
  top: 0px;
  border-left: 0.1rem solid #c7c7cc;
  height: 100%;
}

.vertical-line.date-list {
  display: block;
}
