body {
  box-sizing: border-box;
}

input:disabled {
  cursor: no-drop;
  background-color: #f2f2f2 !important;
}

button {
  border-width: 0rem;
}

.btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem 2.4rem;
  background-color: transparent;
  justify-content: center;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  border-radius: 0.4rem;
}

button:disabled {
  cursor: alias;
  color: var(--main-txt-white-color);
  background: #c7c7cc;
}

.body-container {
  height: calc(100vh - 6.1rem);
  background: #f5f5f5;
}

/* Rect Select Picker */

.react-select__control {
  border: 0.1rem solid #c7c7cc !important;
  border-color: #c7c7cc !important;
  box-shadow: none !important;
  height: 4rem;
}

.react-select__control--is-disabled.css-16xfy0z-control {
  cursor: alias;
}

.react-select__control:focus-within,
.react-select__control:hover {
  caret-color: #2f80ed;
  border: 0.1rem solid #2f80ed !important;
  background: rgba(61, 178, 103, 0.1);
}

.react-select__value-container,
.react-select__indicator {
  cursor: pointer !important;
}

.react-select__indicator-separator {
  margin-bottom: 0rem !important;
  margin-top: 0rem !important;
  width: 0rem !important;
}

.react-select__indicator {
  color: var(--main-txt-dark-grey) !important;
}

.selectCategory__option--is-selected {
  background-color: #6da6f2;
}

.react-select__single-value {
  font-size: var(--main-line-height-14);
  font-weight: var(--main-font-weight-500);
  color: var(--main-txt-dark-grey) !important;
}

.react-select__option:active,
.react-select__option:focus {
  background-color: #c4c4c463;
}

.react-select__option--is-selected {
  background-color: #c4c4c463;
}

.react-select__option {
  background-color: white !important;
  color: #333333 !important;
  font-size: var(--main-txt-size-14) !important;
  font-weight: var(--main-font-weight-500) !important;
}

.react-select__option--is-selected {
  background-color: transparent !important;
  color: #6da6f2 !important;
  font-weight: var(--main-font-weight-600);
}

.react-select__option--is-focused {
  background-color: #6da6f2 !important;
  color: var(--main-txt-white-color) !important;
}

.react-select__menu {
  border: 0.1rem solid #6da6f2;
  z-index: 3 !important;
}

.react-select__placeholder {
  font-size: var(--main-txt-size-14) !important;
  color: #696b72 !important;
}

/* Input Feild */
.common-input-feild .input-text {
  display: block;
  width: 100%;
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  padding: 0.9rem 1.6rem;
  color: var(--main-txt-black-color);
  border: 0.1rem solid var(--main-border-lite-grey-color);
  border-radius: 0.4rem;
}

input:focus-visible {
  outline: 0.1rem solid var(--main-border-lite-grey-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* powerde by logo */
.powered-by {
  flex-basis: 10%;
}

.powered-by-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.powered-by-wrapper label {
  color: #283988;
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  margin-bottom: 1rem;
}

/* Scroll bar thumb */
/* scrollbar */
::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  background: rgba(17, 99, 102, 0.5);
  -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(17, 99, 102, 0.5);
}

/*react select-user*/

.react-select-user__control {
  box-shadow: none !important;
  width: 18rem !important;
}

.react-select-user__control:focus-within,
.react-select-user__control {
  caret-color: none;
  border: none !important;
}

.react-select-user__value-container,
.react-select-user__indicator {
  cursor: pointer !important;
}

.react-select-user__indicator-separator {
  margin-bottom: 0rem !important;
  margin-top: 0rem !important;
  width: 0rem !important;
}

.react-select-user__single-value {
  font-size: var(--main-line-height-14);
  font-weight: var(--main-font-weight-500);
  color: var(--main-txt-dark-grey) !important;
}

.react-select-user__option {
  background-color: white !important;
  color: #333333 !important;
  font-size: var(--main-txt-size-14) !important;
  font-weight: var(--main-font-weight-500) !important;
}

.react-select-user__menu {
  z-index: 3 !important;
}

.react-select-user__option:hover {
  background-color: #3db267 !important;
  color: #fff !important;
}
