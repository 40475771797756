.tree-container.draft-tree:hover {
  background: #c2ebca66;
}
.tree-container.draft-tree.active {
  background: #c2ebca66;
}
.tree-container.draft-tree:hover .tree-edit-button {
  display: flex;
}
.tree-edit-button {
  display: none;
}
.tree-title.tree-dates {
  font-size: var(--main-txt-size-12);
  font-style: italic;
  color: var(--main-txt-lite-grey);
}
