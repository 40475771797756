.request-details {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.request-aoi {
  width: 40%;
  padding: 0.5rem 0 0 0;
  border-right: 0.1rem solid #f2f2f2;
}

.region-heading {
  height: 3.5rem;
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  padding-left: 1rem;
  border-bottom: 0.1rem solid #f2f2f2;
  align-items: center;
  display: flex;
}

.aoi-list {
  height: 10rem;
  border-bottom: 0.1rem solid #f2f2f2;
  padding: 0.5rem 0;
  overflow: auto;
}

.aoi-list::-webkit-scrollbar {
  width: 0.3rem;
}

.aoi-list::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.aoi-list::-webkit-scrollbar-thumb {
  background: #c7c7cc;
  border-radius: 0.5rem;
}

.aoi-list::-webkit-scrollbar-thumb:hover {
  background: #c7c7cc;
}

.individual-aoi {
  display: flex;
  padding: 0 1rem 0.5rem 1rem;
  cursor: pointer;
}

.individual-aoi:hover {
  background: linear-gradient(
      0deg,
      rgba(61, 178, 103, 0.1) 0%,
      rgba(61, 178, 103, 0.1) 100%
    ),
    #fff;
}

.individual-aoi p {
  padding: 0.5rem 1rem;
  color: #4f4f4f;
  font-size: var(--main-txt-size-14);
}

.request {
  padding: 3.2rem 4rem 0 4rem;
  position: relative;
}

.request-card-sections {
  display: flex;
  height: 70rem;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
  overflow-x: auto;
  transition: scroll-left 0.3s ease;
}

.request-card-sections::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.request-card {
  min-width: 33.2rem;
  max-width: 33.2rem;
  margin-right: 4.8rem;
}

.request-card:first-child {
  margin-left: 3.5rem;
}

.request-card-header {
  cursor: pointer;
  padding: 0.6rem 1.6rem;
  border-radius: 0.4rem;
  background: linear-gradient(
      0deg,
      rgba(47, 128, 237, 0.15) 0%,
      rgba(47, 128, 237, 0.15) 100%
    ),
    #fff;
  display: flex;
}

.progress-bar-wrapper {
  width: 100%;
}

.request-card li {
  cursor: context-menu;
}

.request-card-header h2 {
  display: flex;
  align-items: center;
  flex-basis: 95%;
  color: var(--main-txt-blue);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
}

.request-card-header label {
  flex-basis: 5%;
  text-align: end;
  cursor: pointer;
}

.request-card-body {
  border-radius: 0.4rem;
  border: 0.1rem solid #2f80ed;
  background: #fff;
}

.request-card-container {
  padding: 1.6rem;
}

.request-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-rang-list {
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-list-value {
  flex-basis: 65%;
  text-align: end;
  color: #42444a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.list-details {
  color: var(--main-txt-blue);
  text-decoration: underline;
  cursor: pointer;
}

.modify-req-para {
  color: var(--main-txt-sure-grey);
  font-size: var(--main-txt-size-12);
  margin-bottom: 1.6rem !important;
}

.file-list {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-600);
  padding: 0.4rem 0rem 0.8rem 0rem;
  display: flex;
  justify-content: flex-end;
}

.request-footer p {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-600);
}

.draft-footer {
  padding: 0.6rem 1.6rem 0.7rem 1.6rem;
  background: rgba(242, 201, 76, 0.45);
}

.draft-footer li {
  display: flex;
  justify-content: space-between;
}

.draft-footer p {
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.request-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0rem;
}

.request-status label {
  flex-basis: 70%;
}

.request-status span {
  flex-basis: 22%;
  text-align: end;
  color: var(--syntax-blue, #2f80ed);
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  padding-top: 0.1rem;
}

.request-tab-list {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.2rem solid #f2f2f2;
  margin-top: 1rem;
}

.request-tab {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  padding: 0.4rem 3.6rem;
  text-align: center;
  font-weight: var(--main-font-weight-500);
  margin-right: 2%;
  cursor: pointer;
  color: var(--sat-sure-grey-sat-sure-grey-2, #696b72);
  font-size: var(--main-txt-size-14);
}

@media (max-width: 76.8rem) {
  .request-tab {
    width: 100%;
    margin-right: 0;
    font-size: var(--main-txt-size-14);
  }

  .request-tab-panel-container {
    padding: 1% 3% 1% 3%;
  }
}

@media (min-width: 76.8rem) and (max-width: 102.4rem) {
  .request-tab {
    margin-right: 1%;
    width: 11rem;
    font-size: var(--main-txt-size-16);
  }

  .request-tab-panel-container {
    padding: 2% 3% 2% 3%;
  }
}

.request-tab[aria-selected='true'] {
  position: relative;
  color: #3db267;
}

.request-tab[aria-selected='true']::after {
  content: '';
  position: absolute;
  bottom: -0.1rem;
  width: 100%;
  height: 0.2rem;
  background-color: #3db267;
}
/* data product admin dropdown */
.data-product-admins__control {
  width: 22.2rem;
  height: 3.2rem;
  position: absolute !important;
  margin-top: 1.2rem;
  right: -50%;
}
.project-member-placeholder {
  text-align: justify;
}
.project-member-placeholder p,
.data-product-admins__input-container {
  color: var(--main-txt-lite-grey);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-400);
}
.data-product-admins__indicators {
  display: none !important;
}
.data-product-admins__control--is-focused {
  border: 1px solid #3db267 !important;
  outline: none !important;
  box-shadow: none !important;
}

.data-product-admins__option {
  text-align: justify;
}
.data-product-admins__option--is-focused,
.data-product-admins__option--is-selected {
  background-color: #3db267 !important;
}
.data-product-admins__option--is-focused p,
.data-product-admins__option--is-selected p {
  color: white;
}
.data-product-admins__single-value {
  text-align: justify;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-400);
}
.data-product-admins__menu {
  display: inline-table;
  right: -46%;
  margin-top: 5.2rem !important;
  width: 22.2rem !important;
}
.data-product-admins__menu-list {
  height: 16rem !important;
}
