.draft-product-card {
  padding: 2rem;
}
.modify-request-body.draft-wrapper-list {
  height: 21rem;
  overflow: auto;
}

.modify-request-header.draft-card-header {
  background: #c7c7cc;
}

.modify-request-header.draft-card-header li:first-child h2 {
  color: #ffffff;
}
.modify-request-content.draft-content {
  border: 1px solid #95969d;
}

/* .draft-header {
  display: block;
  padding: 1.1rem 1.6rem 1.1rem 0rem;
  border-radius: 0.4rem;
  background: linear-gradient(
      0deg,
      rgba(47, 128, 237, 0.15) 0%,
      rgba(47, 128, 237, 0.15) 100%
    ),
    #fff;
}

.draft-header li:first-child {
  text-align: center;
  flex-basis: 87%;
}

.draft-header li:first-child h2 {
  padding-left: 3rem;
  color: var(--main-txt-blue);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
}

.draft-header li:last-child {
  display: flex;
  justify-content: space-between;
  flex-basis: 13%;
}

.draft-body {
  margin-top: 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #2f80ed;
  background: #fff;
} */

/* .draft-container {
  padding: 1.6rem;
} */

.request-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-range-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modify-req-para {
  color: var(--main-txt-sure-grey);
  font-size: var(--main-txt-size-12);
  margin-bottom: 1.6rem !important;
  padding-left: 3rem;
}

.request-list:nth-child(-n + 2) .list-value {
  text-align: end;
  color: var(--main-txt-blue);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.date-range-list:nth-child(-n + 2) .list-value {
  text-align: end;
  color: var(--main-txt-blue);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.date-draft-input {
  width: 100%;
  padding: 0.5rem 0.8rem;
  height: 2.8rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #c7c7cc;
}

.list-value {
  cursor: pointer;
  flex-basis: 70%;
}

.file-list {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-600);
  padding: 0.4rem 0rem 0.8rem 0rem;
}

.request-footer {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-600);
}

/*react-select*/

.draft-select .react-select__control {
  border-color: #c7c7cc !important;
  box-shadow: none !important;
  margin-bottom: 0.8rem;
  height: 0rem !important;
}

.draft-footer {
  padding: 0.6rem 1.6rem 0.7rem 1.6rem;
  background: rgba(242, 201, 76, 0.45);
}

.draft-footer li {
  display: flex;
  justify-content: space-between;
}

.draft-footer p {
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}
