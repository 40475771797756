.documentation-sections {
  flex-basis: 15%;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.12);
}

.documentation-list {
  flex-basis: 95%;
}

.documentation-product-container {
  display: flex;
}

.side-nav-container {
  flex-basis: 20%;
  padding: 20px;
}

.sidenav-doc-container {
  flex-basis: 25%;
  padding: 20px;
  border-left: 1px solid #f2f2f2;
}

/* Common Documentation */
.documentation-container {
  display: flex;
  height: calc(100vh - 60px);
  background: #fff;
}

.document-content-sections {
  height: calc(100vh - 110px);
  overflow-y: scroll;
  margin: 25px 20px;
}

.document-content-sections::-webkit-scrollbar {
  display: none;
}

.response-section span pre {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-19);
  color: #42444a;
}

.project-search {
  width: 146px;
  position: relative;
  padding: 16px 20px;
}

.project-search img {
  position: absolute;
  inset: 27px;
}

.project-search .search-input {
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #fff;
  outline: none;
  width: 696px;
  height: 42px;
  padding-left: 36px;
}

.card-container {
  padding: 20px;
}

.card-container h2 {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-24);
  font-weight: var(--main-font-weight-700);
  padding-bottom: 8px;
}

.card-container p {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  padding-bottom: 24px;
}

.card-product {
  display: inline-flex;
  padding: 24px 10px 40px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 4px;
  border: 1px solid var(--Gray-6, #f2f2f2);
  background: var(--neutral-white, #fff);
  margin: 24px;
  width: 332px;
  height: 150px;
  cursor: pointer;
}

.card-product h3 {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-500);
}

.card-product p {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-12);
}

.card-product:hover {
  border-radius: 4px;
  border: 1px solid var(--sparta-sparta-dark-1, #3db267);
  background: var(--neutral-white, #fff);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.card-div {
  display: flex;
}
