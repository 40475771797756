.sop-detail-section {
  display: flex;
  justify-content: space-around;
  padding: 2rem 0rem;
}

.sop-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-basis: 33.3%;
  width: 33.3%;
}

.sop-list-title {
  color: #696b72;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.region-value {
  font-size: var(--main-txt-size-14);
  color: #2f80ed;
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-20);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 40%;
}

.sop-horizontal-line {
  border: 0.1rem solid #f2f2f2;
}

.sop-cards-wrapper {
  display: flex;
  margin: 4rem;
  overflow-y: auto;
  height: 60vh;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
}

.sop-cards-wrapper::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.sop-cards {
  background: white;
}

.sop-card-header {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3db9161a;
  color: #3db267;
  font-size: var(--main-txt-size-14) !important;
  font-weight: var(--main-font-weight-600) !important;
  border-radius: 0.5rem;
}

.sop-card-body {
  margin-top: 0.5rem;
  background: white;
  border: 0.1rem solid #c7c7cc;
  border-radius: 0.3rem;
  width: 32.6rem;
}

.next-process {
  display: flex;
  height: 4rem;
  justify-content: center;
  align-items: center;
}

.next-process .sop-image-wrapper {
  padding: 5px;
}

.card-wrapper {
  display: flex;
}

.sop-card-list {
  width: 100%;
  height: 29rem;
  overflow: auto;
}

.list-item-left {
  flex-basis: 35%;
}

.list-item-right {
  flex-basis: 65%;
}

.left-list-item {
  width: 70%;
}

.right-list-item {
  position: relative;
  width: 30%;
}

.qa-check-validations {
  position: absolute;
  top: 24px;
  width: 78px;
  z-index: 9;
  right: 30px;
  padding: 0.8rem;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.qa-qc-check-remove {
  display: none;
}

.qa-qc-check-add {
  display: block;
}

.qa-check-validations li:not(:last-child) {
  margin-bottom: 0.8rem;
}

.qa-check-validations li {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.qa-check-validations li label {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  flex-basis: 50%;
}

.qa-check-validations li label img {
  width: 100%;
  height: 100%;
}

.qa-check-validations li span {
  flex-basis: 50%;
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.left-list-item,
.list-item-heading {
  color: #95969d;
  font-size: var(--main-txt-size-14);
  padding: 5px 10px;
}

.right-list-wrapper span {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  color: var(--main-txt-lite-grey);
}

.sop-selection-checks {
  display: flex;
  justify-content: space-evenly;
}

.check-qa-qc-hover:hover {
  background-color: #dadada;
}

.aq-qc-li:hover {
  background-color: rgba(191, 191, 191, 0.15);
}

.sop-selection-icon {
  float: right;
  cursor: pointer;
  background: transparent;
  padding: 0.6rem;
  border-radius: 0.5rem;
  width: 3rem;
  height: 3rem;
}

.sop-selection-valid-icon {
  float: right;
  cursor: pointer;
  background: transparent;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0px;
}

.sop-selection-valid-icon:disabled,
.sop-selection-icon:disabled {
  cursor: not-allowed;
  background: #f4f4f4;
}

.sop-selection-valid-icon:disabled .qa-icon,
.sop-selection-icon:disabled .qa-icon {
  cursor: not-allowed;
}

.sop-selection-valid-icon img,
.sop-selection-icon img {
  width: 100%;
  height: 100%;
}

.data-access-list label {
  display: flex;
  cursor: pointer;
  justify-content: center;
  flex-basis: 50%;
  color: #3db267;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.data-access-list .full-flex-basic {
  flex-basis: 100%;
}

.data-access-list span {
  flex-basis: 25%;
  width: 100%;
  height: 100%;
}

.disabled-details {
  display: none;
  position: absolute;
  top: -40px;
  color: var(--neutral-white, #fff);
  font-size: var(--main-txt-size-12);
  background-color: #000000cc;
  border-radius: 0.5rem;
  padding: 0.6rem;
  width: 15rem;
  text-align: start;
}

.disabled-details::before {
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  background: #000;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  content: '';
  height: 9px;
  position: absolute;
  bottom: -9px;
  width: 15px;
}

.data-access-list {
  width: 100%;
  cursor: pointer;
  justify-content: end;
  display: flex;
  align-items: center;
  padding: 14px 5px;
  background-color: transparent;
  position: relative;
}

.data-access:first-child .data-access-list .disabled-details {
  top: 4rem;
  z-index: 1;
}

.data-access:first-child .data-access-list .disabled-details::before {
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  top: -9px;
}

.data-access-list:hover {
  background: #3db267;
}

.data-access-list:hover span svg path {
  fill: #fff;
}

.data-access-list:disabled:hover p {
  display: block;
}

.data-access-list:disabled:hover label {
  color: var(--main-txt-sure-grey);
}

.data-access-list:disabled span svg path {
  fill: #696b72;
}

.data-access-list:disabled:hover {
  background: #f2f2f2;
}

.data-access-list:hover label {
  color: #fff;
}

.data-access-list:disabled {
  background: #fff;
}

.data-access-list:disabled label {
  cursor: alias;
  color: var(--main-txt-sure-grey);
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-wrapper .list-item:last-child {
  padding: 14px 5px;
}

.card-wrapper .list-item:not(:last-child) {
  padding: 14px 5px;
  border-bottom: 1px solid lightgray;
}

.data-access:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.list-item-left p {
  cursor: no-drop;
  font-size: var(--main-txt-size-14);
  color: var(--main-txt-sure-grey);
  font-weight: var(--main-font-weight-500);
}

.slider-item {
  height: 6rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
}

.sop-card-body:hover {
  border: 0.1rem solid #3db267;
}

.project.sop-card-body:hover {
  border: 0.1rem solid #c7c7cc;
}

.sample-file-text {
  display: flex;
  justify-content: space-between;
}

.qa-description {
  color: var(--main-txt-lite-grey);
  font-size: var(--main-txt-size-14);
  padding: 0.6rem 0.6rem 0.8rem 0.6rem;
}

.qa-testcases {
  padding: 0.6rem 0.6rem 0 0.6rem;
}

.qa-testcases p {
  margin-bottom: 2.1rem;
}

.sample-file {
  color: #2f80ed;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-20);
  cursor: pointer;
}

.download-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.download-card .download-card-item {
  height: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 1.4rem;
}

.download-card-body {
  width: 14.125rem;
}

.download-card-item span {
  margin-left: 1rem;
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
}

.download-card-item {
  font-weight: var(--main-font-weight-500);
  color: #3db267;
}

.download-card-item span img {
  width: 100%;
  height: 100%;
}

.download-card-dates {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.download-card-dates p {
  margin-left: 1rem;
  font-size: var(--main-txt-size-14);
  color: var(--main-txt-dark-grey);
}

.individual-dates {
  display: flex;
  flex-direction: row;
  padding-bottom: 0.7rem;
}

.sample-file-text span {
  font-size: var(--main-txt-size-12);
  vertical-align: middle;
  color: var(--main-txt--sat-lite-sure-grey);
}

.file-access {
  color: var(--main-txt--sat-lite-sure-grey);
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-20);
}

.card-wrapper-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 6rem;
  border-top: 0.1rem solid #f2f2f2;
  margin-top: 2rem;
}

.list-select__control {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.list-select_control--is-focused {
  border-color: transparent !important;
  box-shadow: none !important;
}

.list-select__indicator-separator {
  display: none !important;
}

.list-select__option--is-selected {
  background-color: #3db267 !important;
  color: #0db02b !important;
}

.list-select__option {
  background-color: white !important;
  color: #333333;
  font-size: var(--main-txt-size-14) !important;
  font-weight: var(--main-font-weight-500) !important;
}

.list-select__option--is-disabled {
  color: gray;
}

.list-select__placeholder {
  font-size: var(--main-txt-size-14) !important;
  color: #696b72 !important;
}

.list-select__menu .list-select__menu-list.list-select__menu-list--is-multi {
  max-height: 14rem !important;
}

.list-select__menu .list-select__menu-list {
  max-height: 14rem !important;
}

.list-select__menu {
  z-index: 21 !important;
}

.list-select__menu-list {
  border-radius: 0.4rem;
}

.list-select__single-value {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  color: var(--main-txt-lite-grey) !important;
}

.list-select__option--is-focused {
  background-color: #3db267 !important;
  color: var(--main-txt-white-color) !important;
}

/* Preview modal css */
.preview-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid #bdbdbd;
  padding-right: 1rem;
}

.preview-header-text {
  color: #333333;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  display: flex;
  align-items: center;
  padding: 1.5rem;
}

.preview-header-action {
  display: flex;
  padding-top: 0.5rem;
}

.preview-header-action img {
  height: 2.4rem;
  width: 2.4rem;
  padding-top: 0.9rem;
  padding-right: 2.8rem;
  cursor: pointer;
}

.copy-button {
  background-color: white;
  color: #3db267;
  height: 2.2rem;
  border-radius: 0.4rem;
  width: 4rem;
  margin-top: 0.3rem;
  font-size: var(--main-txt-size-14);
}

.copy-button:hover {
  background-color: #3db267;
  color: white;
  height: 2.2rem;
  border-radius: 0.4rem;
  width: 4rem;
  margin-top: 0.4rem;
}

.preview-body {
  padding: 2.6rem 2.4rem 3.8rem 2.4rem;
}

.preview-body-text {
  margin-top: 1rem;
}

.sop-selection-feild {
  display: block;
  margin-bottom: 0.5rem;
}

.list-item .css-b62m3t-container {
  width: 100%;
}

/*tooltip-modal*/

.img-tooltip {
  margin-left: 1rem;
  padding-top: 0.8rem;
  cursor: pointer;
}

.details-modal-header {
  display: flex;
  padding: 1rem 0.8rem 1.1rem 0rem;
  border-bottom: 0.1rem solid #f2f2f2;
}

.details-modal-header h1 {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.details-info img {
  margin: 0.2rem 1rem;
}

.details-info-close {
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
}

.details-modal-content {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
  border-bottom: 0.1rem solid #f2f2f2;
}

.details-modal-left {
  flex: 1;
}

.details-modal-left li {
  color: var(--sat-sure-grey-sat-sure-grey-1, #000);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  padding-bottom: 2rem;
}

.details-modal-right {
  flex: 1;
}

.details-modal-right li {
  color: var(--sat-sure-grey-sat-sure-grey-2, #696b72);
  font-size: var(--main-txt-size-14);
  letter-spacing: 0.014rem;
}

.learn-btn-div {
  display: flex;
  justify-content: right;
  padding-top: 1rem;
  margin-right: 1.8rem;
}

.learn-btn {
  height: 2.7rem;
  width: 9rem;
  color: white;
  background: var(--main-txt-green);
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: var(--main-txt-size-14);
}

.format-select.css-b62m3t-container {
  width: 200px !important;
}

.list-select__value-container--is-multi {
  height: auto !important;
}

.list-select__control--is-focused {
  border: 1px solid rgba(61, 178, 103, 1) !important;
}

.list-select__multi-value {
  background: rgba(61, 178, 103, 0.2) !important;
  font-size: 14px;
}

.list-select__multi-value__label {
  color: rgba(61, 178, 103, 1) !important;
}

.format-filter .react-select__control {
  margin-bottom: 0px;
  border: 1px solid var(--sat-sure-grey-sat-sure-grey-4, #c7c7cc) !important;
  border-radius: 4px;
  height: 35px;
}

.format-filter-select--is-disabled {
  width: 200px !important;
}

.format-filter .react-select__control--is-focused {
  box-shadow: none;
}

.format-filter .react-select__control:focus-within,
.format-filter .react-select__control:hover {
  border: 1px solid #3db267 !important;
  background-color: #fff;
  color: var(--sat-sure-grey-sat-sure-grey-3, #95969d);
}

.format-filter .react-select__indicator {
  color: var(--main-txt-black-color) !important;
}

.format-filter .react-select__placeholder {
  color: var(--main-txt--sat-lite-sure-grey) !important;
  font-size: var(--main-line-height-14);
}

.format-filter-select {
  box-shadow: none !important;
  margin-bottom: 0.8rem;
  height: 4rem;
}

.format-filter-select__indicator-separator {
  display: none !important;
}

.format-filter-select__control--is-focused {
  box-shadow: none !important;
  border: 1px solid #3db267 !important;
}

.format-filter-select__placeholder,
.format-filter-select__single-value,
.format-filter-select__menu-list {
  font-size: var(--main-txt-size-14) !important;
}

.format-filter-select__control
  .format-filter-select__control--is-focused
  .format-filter-select__control--menu-is-open {
  color: #000;
}

.format-filter-select__option {
  background-color: white !important;
  color: #333333;
  font-size: var(--main-txt-size-14) !important;
  font-weight: var(--main-font-weight-500) !important;
}

.format-filter-select__option--is-selected {
  background-color: transparent !important;
  color: #333333 !important;
}

.format-filter-select__option:hover {
  background-color: #3db267 !important;
  color: var(--main-txt-white-color) !important;
}

.format-filter-select__menu-list .format-filter-select__option--is-selected {
  background-color: #3db267;
}

.format-filter-select__menu-list
  .format-filter-select__option:not(
    .format-filter-select__option--is-selected
  ) {
  background-color: white;
}

/* Download Modal */
.download-modal-header {
  padding: 0.9rem 1.6rem;
  border-bottom: 1px solid #e0e0e0;
}

.download-modal-header p {
  color: #42444a;
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-600);
}

.download-modal-content {
  padding: 1.6rem 2.1rem;
  border-bottom: 1px solid #e0e0e0;
}

.download-modal-content div {
  display: flex;
  flex-direction: row;
  padding-bottom: 2rem;
}

.aoi-file-name {
  color: #3db267;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  padding-bottom: 1.6rem;
}

.radio-text {
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  margin-left: 0.5rem;
}

.download-modal-footer {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.cncl {
  color: #3db267;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  background-color: transparent;
  cursor: pointer;
  padding: 0.5rem 1.6rem;
}

.cncl:hover {
  text-decoration: underline;
}

.download-btn {
  background-color: #3db267;
  color: white;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  cursor: pointer;
  padding: 0.5rem 1.6rem;
  margin-right: 0.5rem;
  border-radius: 4px;
}

.upload-button {
  display: flex;
  padding: 2px 15px 3px 15px;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  border-radius: 4px;
  border: 1px solid var(--sparta-sparta-dark-1, #3db267);
  cursor: pointer;
  color: var(--main-txt-green) !important;
  font-size: var(--main-txt-size-14);
  white-space: nowrap;
}

.disabled-upload-button {
  display: flex;
  padding: 2px 15px 3px 15px;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  border-radius: 4px;
  border: 1px solid var(--main-txt-lite-grey);
  cursor: pointer;
  color: var(--main-txt-lite-grey) !important;
  font-size: var(--main-txt-size-14);
  white-space: nowrap;
  background: #f2f2f2;
}

.disabled-upload-button input {
  display: none;
}

.files-list {
  text-align: center;
  max-height: 14rem;
  overflow-y: auto;
}

.files-list li {
  display: flex;
  justify-content: space-between;
  margin: 16px;
}

.files-list li label {
  color: #2f80ed;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  cursor: pointer;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-list li span {
  color: #696b72;
  font-size: var(--main-txt-size-10);
}

.list-item-top {
  align-items: baseline;
}

.files-list li:not(:last-child) {
  margin-bottom: 1rem;
}

.upload-button input {
  display: none;
}

.dynamic-text-box {
  background: white;
  border: 1px solid #f2f2f2;
  height: 30px;
  width: 96%;
  border-radius: 3px;
}

.select-region-sop {
  color: #2f80ed !important;
  text-decoration: underline;
  font-weight: 500 !important;
  cursor: pointer;
  padding: 0rem 0.3rem;
  font-size: var(--main-txt-size-14);
}

.display-bands {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 8px;
  margin: 2px;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  color: var(--SatSure-Grey-SatSure-Grey-3, #95969d);
}
