@import '../../assets/styles/modules/mixins.scss';

.pagination_wrapper {
  .pagination_container {
    @include align-center;
    padding: 1.7rem 2rem 0rem 2rem;
    justify-content: flex-end;

    .user_pagination {
      border-bottom: none;
    }
  }
}
