@import '../../assets/styles/modules/mixins.scss';

.map_wrapper {
  @include dimensions(100%, 100%);
  position: relative;
  overflow: hidden;

  .map_layer_switch_sections {
    @include space-between;
    position: absolute;
    top: 1.6rem;
    right: 2.4rem;
    z-index: 1;
    border-radius: 0.4rem;
    background: rgba(37, 37, 37, 0.9);
    padding: 0.8rem 2.2rem;

    li {
      cursor: pointer;
      @include font-profile-12-semibold;
      color: var(--main-txt-white-grey-color);

      &:first-child {
        margin-right: 2.9rem;
      }

      &.active {
        color: var(--main-txt-white-color);
      }
    }
  }

  .common_layer_product_legend {
    position: absolute;
    bottom: 2rem;
    right: 4rem;
    z-index: 1;

    &.single_layer_product_legend {
      bottom: 9rem;
    }

    &.multi_layer_product_legend {
      width: 96%;
      @include space-between;
    }

    .layer_legend {
      img {
        @include dimensions(100%, 100%);
      }
    }
  }

  .product_available_date_container {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 34rem;
    transform: translate(0%, -50%);
    border-radius: 0.4rem;
    background: rgba(37, 37, 37, 0.75);
    padding: 1.3rem 0.5rem 1.3rem 2rem;

    &.active {
      left: 4rem;
    }

    .onclick_date_picker_section {
      @include center;
      margin-bottom: 1.1rem;
      cursor: pointer;

      label {
        @include font-profile-14-semibold;
        cursor: pointer;
      }

      span {
        padding: 0.5rem 0rem 0rem 0.5rem;
      }
    }

    .date_scroll_sections {
      position: relative;
      width: 100%;

      .date_list {
        scrollbar-width: none;
        overflow-y: scroll;
        max-height: 23rem;
        margin: 2rem 0rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .particular_date {
          position: relative;
          @include align-center;

          &:first-child {
            .date_horizontal_tree_line {
              height: 51%;
              bottom: 0.9rem;
            }
          }

          &:not(:last-child) {
            padding-bottom: 1.9rem;
          }

          &:last-child {
            .date_horizontal_tree_line {
              top: -1rem;
            }
          }

          .date_horizontal_tree_line,
          .date_vertical_tree_line {
            position: absolute;
            background-color: var(--main-bg-white-color);
          }

          .date_horizontal_tree_line {
            border-left: 0.1rem solid var(--main-border-white-color);
            height: 100%;
          }

          .date_vertical_tree_line {
            @include dimensions(1rem, 0.07rem);
          }

          .date_value {
            @include font-profile-12-medium;
            color: var(--secondary-txt-green);
            margin-left: 1rem;
            padding: 0.2rem 0.4rem;
            border-radius: 0.4rem;
            cursor: pointer;

            &:hover,
            &.active {
              color: var(--main-txt-white-color);
            }

            &.active {
              font-weight: var(--main-font-weight-600);
              background-color: var(--main-txt-green);
              border-radius: 0.4rem;
            }
          }
        }
      }

      .onclick_scroll_btn {
        position: absolute;
        width: 100%;
        background: rgba(37, 37, 37, 0.75);
        cursor: pointer;

        &.top,
        &.bottom {
          &.disabled {
            display: none;
          }
        }

        &.top {
          top: -2rem;
        }

        &.bottom {
          bottom: -2rem;
        }
      }
    }
  }
}

.spinner {
  position: relative;

  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    @include dimensions(4rem, 4rem);
    margin: -2rem;
    border-radius: 50%;
    border: 0.5rem solid rgb(252, 252, 252);
    border-top-color: rgba(0, 0, 0, 0.6);
    animation: spinner 0.6s linear infinite;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
