.no-drafts-wrapper {
  height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-draft-sub-header {
  color: var(--main-txt-sure-grey);
  line-height: var(--main-line-height-20);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.no-draft-sub-header:not(:last-child) {
  margin-top: 1.6rem;
}
