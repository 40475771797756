.drafts-wrapper {
  padding: 1.6rem;
}

.drafts-list {
  padding-top: 0.16rem;
}

.draft-item {
  display: flex;
  cursor: pointer;
  padding: 0.5rem 2rem 0.5rem 2rem;
}

.draft-item:hover {
  background: rgba(194, 235, 202, 0.4);
}

.draft-item .filename {
  overflow: hidden;
  color: #4f4f4f;
  text-overflow: ellipsis;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.draft-item .modified-date {
  padding-left: 4rem;
  color: #95969d;
  font-size: var(--main-txt-size-12);
  line-height: 2rem;
}

.drafts-search {
  width: 245px;
  height: 26px;
  border: 1px solid #c7c7cc;
  border-radius: 4px;
  padding: 3px;
}

.hover-draft-icons {
  display: flex;
  width: 40%;
  height: 100%;
  cursor: pointer;
  justify-content: flex-end;
}

.hover-draft-icons img {
  padding: 0.2rem 0.5rem;
}

.drafts-tree-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  text-overflow: ellipsis;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.drafts-tree-container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 0.6rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  background-color: transparent;
  border-radius: 0.4rem;
  cursor: pointer;
}

.trash-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.drafts-tree-container:hover {
  border-radius: 4px;
  background: rgba(194, 235, 202, 0.4);
  margin-right: 15px;
}

.drafts-tree-container:hover .trash-icon {
  display: block;
}

.drafts-date {
  margin-left: 10px;
  color: var(--SatSure-Grey-SatSure-Grey-3, #95969d);
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.edit-icon {
  padding-left: 20px;
}
.drafts-comp-content-wrapper {
  padding: 0rem 1.6rem;
}
