/* Date picker */
.date-picker {
  background-color: #fff;
  height: 100%;
}

.input-field {
  width: 100%;
  position: relative;
}

.input-field.sop {
  width: 100%;
  position: relative;
}

.date-input {
  width: 100%;
  padding: 0.5rem 1.6rem;
  height: 3.5rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #c7c7cc;
  cursor: pointer;
}

.date-input:disabled {
  background: white !important;
}

.date-input:focus-visible {
  outline: 0;
}
.disabled-image {
  cursor: no-drop !important;
}

.calendar-icon {
  position: absolute;
  top: 0.9rem;
  right: 1rem;
  color: #c7c7cc !important;
  cursor: pointer;
}

.date-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.1rem 1.1rem 1.1rem 1.6rem;
  border-bottom: 0.1rem solid#F2F2F2;
}

.date-picker-header h3 {
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
}

.date-picker-content {
  display: flex;
}

.date-info-list {
  border-right: 0.1rem solid#F2F2F2;
  padding: 2.7rem 2.1rem 0rem 1.6rem;
}

.date-process {
  width: 14.5rem;
}

.date-picker-close {
  cursor: pointer;
}

.date-picker-close img {
  width: 100%;
  height: 100%;
}

.date-process:not(:last-child) {
  margin-bottom: 1.6rem;
}

.date-process label {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  border-radius: 100%;
  margin-right: 0.4rem;
}

.date-process.available label {
  background-color: #62d0a2;
}

.date-process.process label {
  background-color: #f2994a;
}

.date-process.upcoming label {
  background-color: #696b72;
}

.date-picker-footer {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 1.6rem 0rem 1.6rem;
  border-top: 0.1rem solid #f2f2f2;
}

.date-picker-dates input,
.date-picker-dates label {
  color: var(--main-txt-dark-grey);
  padding: 0.4rem 0.8rem;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-24);
  border-radius: 0.4rem;
  background: #f4f4f4;
}

.date-picker-dates input {
  border: transparent;
  display: block;
  width: 9rem;
  text-align: center;
}

.date-picker-dates {
  display: flex;
  flex-direction: column;
}

.date-picker-dates span {
  width: 2.4rem;
  height: 2.4rem;
  display: block;
  padding: 0rem 0.8rem;
}

.date-picker-dates p {
  padding-top: 0.4rem;
  color: red;
}

.date-picker-selection {
  display: flex;
  align-items: center;
}

.date-picker-dates span img {
  width: 100%;
  height: 100%;
}

.date-process span {
  cursor: pointer;
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-24);
}

.date-picker-btn {
  display: flex;
  padding-bottom: 2rem;
}

.btn.reset {
  width: 11.8rem;
  border: 0.1rem solid #e2e8f0;
  margin-right: 1.5rem;
}

.btn.done {
  width: 11.8rem;
  color: var(--main-txt-white-color);
  background: #3db267;
}

.btn.done:disabled {
  background: #c7c7cc;
  cursor: not-allowed;
}

.rdrDateDisplayWrapper {
  display: none;
}
