.setting_wrapper {
  display: flex;
  height: calc(100vh - 6.4rem);
  background: var(--main-bg-white-color);

  .setting_sections {
    flex-basis: 17%;
    box-shadow: 0rem 0.4rem 0.8rem 0.1rem rgba(0, 0, 0, 0.12);
    border-right: 1px solid var(--main-border-white-lite-color);
  }

  .setting_container {
    flex-basis: 83%;
  }
}
