@import '../../assets/styles/modules/mixins.scss';

.sidebar_wrapper {
  display: flex;
  position: relative;
  z-index: 9999;

  .sidebar_container {
    @include space-between;
    flex-direction: column;
    width: 9rem;
    padding-bottom: 0.9rem;

    .sidebar_sections {
      .sidebar_list {
        @include center;
        flex-direction: column;
        height: 7rem;
        cursor: pointer;

        &.active {
          border-left: 4px solid var(--main-border-green);
          background-color: var(--main-bg-white-color);
        }

        label {
          width: 2.5rem;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        span {
          @include font-profile-10-medium;
          color: var(--main-txt--sat-lite-sure-grey);
          cursor: pointer;
        }
      }

      .create_new_project {
        height: 4.5rem;
        padding: 0.7rem 1.6rem 0.6rem 1.3rem;
        background-color: white;
        margin-left: 0.7rem;
        margin-right: 0.2rem;
        border-radius: 0.8rem;

        span {
          text-align: center;
          @include font-profile-12-medium;
          color: var(--sparta-sparta-dark-1, #3db267);
          cursor: pointer;
        }

        &.active {
          span {
            color: #3db267;
          }
        }
      }
    }
  }

  .sidebar_collapse_expand_container {
    border-right: 0.1rem solid #e0e0e0;
    position: absolute;
    left: 9rem;
    height: 100%;

    .sidebar_collapse_section {
      display: flex;
      height: 100%;
      position: relative;

      .expand_collapse {
        width: 28.8rem;
        background: #fff;

        &.collapse {
          visibility: collapse;
          width: 0;
          display: none;
        }
      }

      .expand_collapse_arrow {
        position: absolute;
        top: 50%;
        right: -2.6rem;
        @include align-center;
        background-color: white;
        border-radius: 0rem 5rem 5rem 0rem;
        cursor: pointer;
        box-shadow: 0.2rem 0rem 0.4rem rgba(0, 0, 0, 0.25);
        @include dimensions(2.5rem, 5rem);

        .arrow_icon {
          @include dimensions(2rem, 1.4rem);

          &.arrow_rotate {
            transform: rotate(180deg);
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.discard_modal_header {
  @include space-between;
  flex-direction: row;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f2f2f2;

  img {
    cursor: pointer;
  }

  p {
    color: var(--main-txt-dark-grey);
    @include font-profile-14-medium;
  }
}

.discard_modal_content {
  color: var(--main-txt-dark-grey);
  @include font-profile-14-normal;
  padding-top: 1.6rem;
  padding-bottom: 2.4rem;
}

.discard_modal_footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.6rem;
  border-top: 1px solid #f2f2f2;

  .cancel_discard_btn {
    color: var(--main-txt-green);
    @include font-profile-14-medium;
    cursor: pointer;
  }

  .discard_btn {
    color: white;
    background-color: var(--main-txt-green) !important;
    @include font-profile-14-medium;
    padding: 0.5rem 2.4rem !important;
    margin-left: 1rem;
    cursor: pointer;
  }
}

// /* skeleton-loader*/

// .skeleton-loader {
//   animation: loadingAnimation 3s infinite linear;
//   background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
//   background-size: 200% 100%;
//   border-radius: 4px;
//   height: 100%;
//   width: 100%;
// }

// @keyframes loadingAnimation {
//   0% {
//     background-position: -200% 0;
//   }

//   100% {
//     background-position: 200% 0;
//   }
// }
