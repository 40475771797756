.dragable-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 4rem 0.8rem 2.4rem;
  border-bottom: 0.1rem solid #f2f2f2;
  background: white;
}

.resizable {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  width: calc(100% - 9rem);
  overflow: hidden;
  z-index: 9;
}

.drawer-section.active .resizable {
  width: calc(100% - 37.9rem);
  height: calc(100vh - 7rem);
}

.dragable-header h1 {
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-500);
}

.dragable-btn {
  display: flex;
}

.draggable-content {
  display: flex;
}

.modify-card-screen.width-100 {
  width: 100%;
}

.modify-card-screen.width-70 {
  width: 75%;
  flex-basis: 81%;
}

.btn.enabled-request-btn {
  background-color: #3db267;
  color: var(--main-txt-white-color);
  width: 12rem;
  height: 3.2rem;
  margin-left: 1.6rem;
  font-weight: var(--main-font-weight-600);
}

.btn.enabled-request-btn:hover {
  background: rgba(61, 178, 103, 0.75);
}

.btn.disabled-btn {
  width: 12rem;
  height: 3.2rem;
  color: white;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  margin-left: 1.6rem;
}

.save-draft {
  width: 12rem;
  height: 3.2rem;
  padding: 0rem;
}

.btn-green {
  border: 0.1rem solid #3db267;
  color: var(--main-txt-green);
}

.btn-green:disabled {
  border: none;
}

.right-arrow {
  margin-left: 0.8rem;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
}

.right-arrow img {
  width: 160%;
  height: 100%;
  margin-top: 0.2rem;
}

.dragable-body {
  display: flex;
  flex-direction: column;
  background: white;
  height: 100vh;
}

.draggable {
  cursor: pointer;
  position: relative;
  top: 3.7rem;
}

.draggable label {
  cursor: pointer;
  display: block;
  width: 4rem;
  height: 4rem;
}

.dropdown-menu {
  width: 10.4rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
  position: sticky;
  z-index: 1;
}

.dropdown-menu .dropdown-menu-item {
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  background-color: white;
  display: flex;
  justify-content: center;
}

.dropdown-menu li:hover {
  background-color: #3db267;
}

.dropdown-menu .dropdown-menu-item p {
  color: #42444a;
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}

.confirmRequest {
  background-color: #f8f8f8;
  width: 100%;
  flex-basis: 25%;
  border-left: 0.1rem solid #f2f2f2;
}

.confirm-request-body {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.confirm-request-header {
  padding: 0 0 2.4rem 6rem;
  border-bottom: 1px solid white;
}

.confirm-request-header h1 {
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-500);
  color: #42444a;
}

.confirm-request-content {
  padding: 2rem 6rem;
}

.create-new-option {
  display: flex;
  align-items: center;
}

.create-new-option img {
  margin-right: 1rem;
}

.input-div {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.confirm-request-body input {
  height: 3.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--main-txt-white-grey-color);
  padding-left: 1rem;
}

.date-picker-input {
  display: flex;
  justify-content: space-between;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--main-txt-white-grey-color);
  background-color: #fff;
  padding-right: 0.5rem;
  align-items: center;
}

.date-picker-input input {
  border: none !important;
  width: 100%;
  display: block;
}

.confirm-request-body input:focus-visible {
  outline: none !important;
}

.confirm-request-body textarea {
  border-radius: 0.4rem;
  border: 0.1rem solid var(--main-border-grey-color);
  width: 20rem;
  resize: none;
  padding-left: 1rem;
  padding-top: 0.8rem;
}

.confirm-request-body textarea:focus-visible {
  outline: none !important;
}

textarea::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

textarea::-webkit-scrollbar-track {
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.1);
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.2);
}

textarea::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

textarea::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.input-div label {
  color: #696b72;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  margin-bottom: 1rem;
}

.input-div p {
  font-size: 14px;
  font-weight: 500;
  color: #2f80ed;
  text-decoration: underline;
  padding-top: 0.5rem;
  cursor: pointer;
}

.confirm-request-body .description-bar {
  padding-bottom: 5rem;
}

.confirm-button-div {
  display: flex;
  justify-content: flex-end;
}

.save-draft-button {
  height: 2rem;
  width: 7.5rem;
  border: 0.1rem solid var(--main-txt-green);
  background: white;
  color: var(--main-txt-green);
  border-radius: 0.4rem;
  cursor: pointer;
  visibility: hidden;
}

.request-button {
  height: 3.2rem;
  width: 14.7rem;
  color: white;
  background: var(--main-txt-green);
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.request-button label {
  color: white;
  cursor: pointer;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.project-select__control {
  height: auto;
  border-color: var(--main-txt-white-grey-color) !important;
}

.project-select__menu {
  z-index: 3 !important;
}

.project-select__indicator-separator {
  display: none;
}

.project-select__control--is-focused {
  box-shadow: none !important;
  font-size: var(--main-line-height-14);
  outline: none !important;
  border-color: var(--main-txt-green) !important;
}

.project-select__input-container {
  margin: 0 !important;
  padding: 0 !important;
  font-size: var(--main-line-height-14);
}

.project-select__single-value {
  font-size: var(--main-line-height-14);
}

.project-select__option {
  height: 3.2rem;
  font-size: 1.4rem !important;
}

.project-select__placeholder {
  color: var(--main-txt-sure-grey) !important;
  font-size: var(--main-line-height-14) !important;
}

.project-select__option--is-focused {
  background-color: var(--main-txt-green) !important;
  color: var(--main-txt-white-color) !important;
}

.project-select__option--is-selected {
  background-color: var(--main-txt-green) !important;
  color: var(--main-txt-white-color) !important;
}

.disabled-project-select__control {
  margin-bottom: 0rem;
  border: 0.1rem solid #e0e0e0 !important;
  border-radius: 0.8rem;
  height: 3.5rem;
  pointer-events: none;
}

.disabled-project-select__control--is-focused {
  box-shadow: none !important;
  font-size: var(--main-line-height-14);
}

.disabled-project-select__indicator {
  color: var(--main-txt-black-color) !important;
  cursor: not-allowed !important;
}

.disabled-project-select__placeholder {
  color: var(--main-border-grey-color) !important;
  font-size: var(--main-txt-size-10) !important;
  padding-left: 0.8rem;
}

.disable-project-select__value-container {
  font-size: var(--main-txt-size-14);
  height: 3.2rem;
  cursor: not-allowed !important;
}

.project-select__value-container--is-multi {
  max-height: 7.6rem !important;
  overflow: auto !important;
}

.disable-project-select__control {
  pointer-events: none !important;
}

.disable-project-select__placeholder {
  padding-bottom: 1rem;
}

.disable-project {
  cursor: not-allowed !important;
}

.tooltip {
  position: relative;
  left: 5%;
  transform: translateX(-30%);
  padding: 5px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    #fff;
  color: #fff;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  font-size: 12px;
  z-index: 10 !important;
  overflow-wrap: break-word;
  height: auto;
  max-width: 270px;
}

.draggable:hover .tooltip {
  opacity: 1;
}
