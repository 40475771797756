.modify-request {
  flex: 1;
  padding: 1.2rem 0 4.2rem 2.4rem;
}

.modify-request-card {
  min-width: 33.2rem;
  max-width: 33.2rem;
  height: 50rem;
  margin-left: 3rem;
}

.modify-request-card:last-child {
  margin-right: 5rem;
}

.modify-request-card:last-child
  .modify-request-header
  .info-icon
  .header-icons
  .dropdown-menu-modal {
  right: 0;
}

.modify-request-header {
  display: flex;
  justify-content: space-between;
  padding: 1.1rem 1.6rem 1.1rem 0rem;
  border-radius: 0.4rem;
}

.product-header {
  background: linear-gradient(
      0deg,
      rgba(47, 128, 237, 0.15) 0%,
      rgba(47, 128, 237, 0.15) 100%
    ),
    #fff;
  cursor: pointer;
}

.product-header:hover {
  background: linear-gradient(
      0deg,
      rgba(47, 128, 237, 0.7) 0%,
      rgba(47, 128, 237, 0.7) 100%
    ),
    #fff;
}

.header-icons:hover {
  background: linear-gradient(
      0deg,
      rgba(47, 128, 237, 0.3) 0%,
      rgba(47, 128, 237, 0.3) 100%
    ),
    #fff;
}

.empty-product-header {
  justify-content: center;
  border: 0.1rem solid #2f80ed;
  background: #fff;
  cursor: pointer;
}

.empty-product-header h2 {
  color: var(--main-txt-blue);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  cursor: pointer;
}

.modify-request-header li:first-child {
  text-align: center;
  flex-basis: 87%;
}

.modify-request-header li:first-child h2 {
  padding-left: 3rem;
  color: var(--main-txt-blue);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
}

.product-header:hover li:first-child h2 {
  color: #fff;
}

.info-icon {
  display: flex;
  justify-content: flex-end;
  flex-basis: 13%;
  position: relative;
}

.modify-request-content {
  margin-top: 0.8rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #2f80ed;
  background: #fff;
}

.delivery-details {
  margin-bottom: 1.6rem;
}

.modify-request-body {
  padding: 1.6rem;
}

.request-list .list-value.select-list-value.list-disable {
  color: var(--main-txt-white-grey-color) !important ;
  font-weight: var(--main-font-weight-500) !important;
  cursor: alias;
  text-decoration: none;
}

.date-disabled {
  font-size: var(--main-txt-size-14);
  color: #42444a !important;
  font-weight: var(--main-font-weight-500) !important;
  cursor: alias;
  border: transparent;
}

.request-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.list-title {
  flex-basis: 30%;
  color: var(--main-txt-sure-grey);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.modify-req-para {
  color: var(--main-txt-sure-grey);
  font-size: var(--main-txt-size-12);
  margin-bottom: 1.6rem !important;
}

.request-list:nth-child(-n + 2) .list-value {
  text-align: end;
  color: var(--main-txt-blue);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.request-list .list-value.empty-list-value {
  cursor: alias;
  color: var(--main-txt-white-grey-color) !important;
}

.request-list .list-value.select-list-value {
  font-weight: var(--main-font-weight-600) !important;
  text-decoration-line: underline;
}

.list-value-info {
  display: none;
  position: absolute;
  right: -3rem;
  bottom: 2rem;
  z-index: 99;
  border-radius: 0.4rem;
  background: #636363;
  color: var(--neutral-white, #fff);
  text-align: center;
  padding: 0.4rem 0.8rem;
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}

.list-value-item {
  flex-basis: 70%;
}

.list-value-item-crop {
  flex-basis: 54%;
}

.list-value-item:disabled {
  background: #fff !important;
}

.rename-text {
  border-radius: 4px;
  border: 1px solid var(--Blue-1, #2f80ed);
  background: #fff;
  color: #2f80ed;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
}

.list-value-item .react-select__control:focus-within,
.list-value-item .react-select__control:hover {
  background-color: #fff;
}

.react-select.react-select-primary
  .react-select__control.react-select__control--is-disabled {
  background-color: #fff;
  height: 2rem !important;
  height: 1rem;
  border: 1px solid #f2f2f2;
}

.react-select .react-select__placeholder {
  color: #c7c7cc !important;
}

.react-select .react-select__indicator {
  color: #c7c7cc !important;
}

.dropdown-menu-modal {
  width: 103px;
  height: 83px;
  padding: 4px 0px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 1;
}

.dropdown-menu-modal li {
  display: flex;
  padding: 0.6rem 1.6rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
  letter-spacing: 0.003rem;
  color: #42444a;
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}

.dropdown-menu-modal li:hover {
  border-radius: 2px;
  background: linear-gradient(
      0deg,
      rgba(47, 128, 237, 0.2) 0%,
      rgba(47, 128, 237, 0.2) 100%
    ),
    #fff;
}

.delivery-details,
.file-expected {
  color: #42444a;
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-600);
}

.file-expected {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  margin-right: 0.3rem;
  justify-content: flex-end;
}

.price-list {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 1.6rem;
  background: rgba(242, 201, 76, 0.45);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  color: #42444a;
}

.list-value {
  flex-basis: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-value:hover ~ .list-value-info {
  display: block;
}

.request-list:nth-child(4) {
  margin: 0.4rem 0rem 0.8rem;
  justify-content: space-between;
  height: 1.4rem;
}

.request-list:nth-child(5),
.request-list:nth-child(-n + 2) {
  margin-bottom: 1.6rem;
}

.header-icons {
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}

.header-icons .modify-card-info {
  cursor: pointer;
}

.product-header:hover .header-icons .modify-card-info svg path {
  fill: #fff;
}

/* save-draft-modal */

.save-draft-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.6rem 1rem 1.6rem;
  border-bottom: 1px solid #f2f2f2;
}

.save-draft-header label {
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.save-draft-para {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1.6rem 4rem 1.6rem;
}
.save-draft-para p {
  color: #42444a;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
}

.save-draft-para img {
  align-self: center;
}
.save-draft-para span {
  color: #2f80ed;
}

.save-draft-btn {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.6rem 1rem 1.6rem;
  border-top: 1px solid #f2f2f2;
}
.duplicate-okay {
  width: 6.5rem;
  height: 3.2rem;
}

.save-draft-btn .discard {
  color: #3db267;
}

.save-draft-btn .draft {
  color: var(--main-txt-white-color);
  background-color: #3db267;
}

.save-draft-close {
  cursor: pointer;
}

/* carousel */
.custom-scrollbar-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.custom-scrollbar {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  transition: scroll-left 0.3s ease;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
}

.custom-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.scroll-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 4rem;
  border: none;
  height: 100%;
  background: #fff;
  cursor: pointer;
}

.scroll-button.left {
  border-right: 0.1rem solid #f2f2f2;
}

.scroll-button.right.disabled,
.scroll-button.left.disabled {
  display: none;
}

.scroll-button.right {
  border-left: 0.1rem solid #f2f2f2;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

/* Add a class for scroll animation */
.scroll-animation {
  scroll-behavior: smooth;
}

.child-arrow {
  margin: 0rem 0.5rem;
}

.btn.save-button {
  width: 12rem;
  height: 3.2rem;
  border-radius: 0.5rem;
  background: var(--main-txt-green);
}

.dropdown-menu-item.disabled {
  background-color: #e7e7e7;
  color: #666666;
}

.tool-tip {
  display: inline-block;
}

.tool-tip [disabled] {
  pointer-events: none;
}

.request-list .react-select__menu {
  border: 0.1rem solid #6da6f2 !important;
}
