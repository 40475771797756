.project-heading {
  padding: 2rem 4rem 0rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-heading h1 {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-18);
  font-weight: var(--main-font-weight-600);
}

.viewType {
  padding: 0 0.5rem;
}

.detail-back img {
  padding: 0.2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.request-table-row {
  cursor: pointer;
  height: 42px;
  vertical-align: middle;
}

.request-table-row:hover td {
  background: linear-gradient(
      0deg,
      rgba(61, 178, 103, 0.1) 0%,
      rgba(61, 178, 103, 0.1) 100%
    ),
    #fff !important;
}

.request-name-header:hover {
  text-decoration: underline;
  cursor: pointer;
}

.project-detail-header {
  display: flex;
  align-items: center;
  padding: 1.4rem 1.5rem;
  border-bottom: 0.1rem solid #f2f2f2;
}

.project-detail-header span {
  padding: 0.3rem 1rem;
  cursor: pointer;
}

.request-name-header {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-600);
}

.request-table-wrapper {
  height: 60vh;
}

/* Avatar */
.full-name-avatar {
  display: inline-block;
  width: 3.2rem;
  padding: 6px;
  border-radius: 50%;
  border: 0.5px solid var(--gray-bg);
  position: relative;
  background-color: var(--main-txt-white-color);
  cursor: pointer;
}

.full-name-avatar p {
  display: flex;
  justify-content: center;
  color: var(--gray-bg);
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}

.avatar-clubbed {
  background-color: var(--main-txt-white-grey-color);
  border: none;
}

.avatar-clubbed p {
  color: var(--main-txt-white-color);
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}

/*request table action btn*/

.action {
  display: inline-flex;
  height: 27px;
  padding: 2px 8px 1px 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  color: var(--SatSure-Grey-Grey-2, #696b72);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.14px;
  width: 75px;
  background-color: white;
}

.dropdown-menu-request {
  width: 8.4rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
  position: sticky;
  z-index: 1;
}

.dropdown-menu-request .dropdown-menu-request-item {
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  background-color: white;
  display: flex;
  justify-content: center;
}

.dropdown-menu-request .dropdown-menu-request-item p {
  color: #42444a;
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}

.menu-item.approve {
  background: #c2ebca4d;
  border-radius: 5px;
  height: 27px;
  margin-bottom: 5px;
  cursor: pointer;
}

.disabled-action {
  opacity: 30%;
  cursor: not-allowed !important;
}

.disabled-action label {
  cursor: not-allowed !important;
}

.menu-item .approve {
  color: #45924b;
  border-radius: 3px;
  margin-left: 0px;
}

.menu-item.reject {
  background: #eb575726;
  border-radius: 5px;
  height: 27px;
}

.menu-item .reject {
  color: #eb5757;
  border-radius: 3px;
  cursor: pointer;
}

.action-btns {
  display: inline-flex;
  padding: 1px 11px 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.12px;
}

.action-approve {
  display: inline-flex;
  padding: 1px 11px 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.12px;
  background: rgba(194, 235, 202, 0.3);
  color: var(--Sparta-Sparta--75, #3db267);
}

.action-sections {
  position: relative;
  padding-right: 1rem;
}

.action-status-hover {
  display: none;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0.92);
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.12px;
  width: 16rem;
}

.action-reject:hover ~ .action-status-hover,
.action-approve:hover ~ .action-status-hover {
  display: block;
}

.action-reject {
  display: inline-flex;
  padding: 1px 11px 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.12px;
  background: rgba(235, 87, 87, 0.15);
  color: var(--Red, #eb5757);
}

.rejected-person {
  color: #696b72;
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-500);
  display: block;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-item span {
  display: inline-block;
  width: 15px;
}

.action.dropdown-toggle.show {
  border-radius: 5px;
  border: 1px solid lightgray;
}

.menu-item label {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  color: var(--main-txt--sat-sure-grey-grey-3);
  line-height: var(--main-line-height-16);
  margin-left: 5px;
}

.dropdown-toggle::after {
  display: none;
}

.action.dropdown-toggle.show img {
  transform: rotate(180deg);
}

.dropdown-menu-request.option-menu {
  min-width: 90px;
  padding: 8px;
  text-align: center;
  position: absolute;
  inset: 5px 0px auto auto !important;
  margin-top: 30px;
}

/*rejection modal css*/

.rejection-header {
  padding: 13px 12px 13px 23px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

.rejection-header h1 {
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-600);
  color: #42444a;
}

.rejection-header label {
  cursor: pointer;
}

.rejection-content {
  padding: 16px 24px 20px 24px;
}

.text-area {
  margin: 16px 0px;
}

.reason-text {
  display: block;
  height: 150px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid var(--SatSure-Grey-SatSure-Grey-4, #c7c7cc);
  padding: 8px 16px 8px 10px;
  resize: none;
  outline: none;
}

.reason-text::placeholder {
  color: var(--SatSure-Grey-Grey-3, #95969d);
  font-size: 14px;
}

.reason-text.red {
  border: 0.1rem solid #eb5757;
}

.rejection-content h2 {
  color: var(--SatSure-Grey-SatSure-Grey-2, #696b72);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rejection-content sup {
  color: #eb5757;
}

.popup {
  display: flex;
  height: 32px;
  padding: 9px 13px 10px 16px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.button-cancel {
  color: var(--Sparta-Sparta-Dark-1, #3db267);
  background-color: #fff;
  cursor: pointer;
}

.button-apply {
  background-color: var(--Sparta-Sparta-Dark-1, #3db267);
  color: #fff;
  cursor: pointer;
}

.project-admins {
  display: flex;
  justify-content: center;
  padding-left: 1.6rem;
}

.created-by-col {
  display: flex;
  justify-content: center;
}

.request-datepicker-body {
  display: flex;
  justify-content: center;
}

.react-datepicker {
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  line-height: initial;
  height: 25rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

.react-datepicker-popper .react-datepicker__triangle {
  stroke: #e0e0e0 !important;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  fill: #fff;
  border: #e0e0e0;
  color: #fff;
}

.request-datepicker {
  border: none;
  font-size: 12px;
  background-color: #f9f9f9;
  cursor: pointer;
  width: 50%;
}

.request-datepicker:focus-visible {
  outline: none;
}

.react-datepicker__month-container {
  width: 25rem;
  height: 20.5rem;
}

.react-datepicker__triangle {
  stroke: #fff;
}

.react-datepicker__header {
  border-bottom: none;
  background-color: #fff;
  padding: 2rem;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  font-size: 13px;
  margin-top: 0.2rem;
  padding-right: 1rem;
}

.react-datepicker__day-names {
  margin-top: 10px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.45rem;
  font-size: 12px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #3db267 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #3db267;
  color: #fff;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #3db267;
  color: #fff;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #fff;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.1);
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  font-size: 12px;
}

.request-table-list {
  width: 100%;
  border-spacing: 0rem 0.4rem;
}
