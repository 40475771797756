/* Define font faces */
@font-face {
  font-family: 'Manrope';
  src: url('../../font/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Verdana';
  src: url('../../font/Verdana.ttf');
}

/* Global styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  /* Apply 'Manrope' font as default */
}

/* Set base font size to 10px for easier rem calculation */
html {
  font-size: 62.5%;
  /* 1rem = 10px (62.5% of 16px default browser font size) */
}

/* Reset margins and paddings for common elements */
h1,
h2,
h3,
h4,
ul,
p {
  margin: 0;
  padding: 0;
  margin-bottom: 0;
}

// img {
// width: 100%;
// height: 100%;
// }

/* Remove default list styles */
li {
  list-style-type: none;
}

/* Remove underlines and inherit color for links */
a,
Link {
  text-decoration: none;
  color: inherit;
}

/* Hide open layer map controls */
.ol-zoom,
.ol-full-screen-false {
  display: none !important;
}

/* Scroll bar thumb */
/* scrollbar */
::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.5rem;

  &-track {
    -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
  }

  &-thumb {
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
    background: rgba(17, 99, 102, 0.5);
    -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.5);

    &:window-inactive {
      background-color: rgba(17, 99, 102, 0.5);
    }
  }
}

/* Define width classes */
.width_20 {
  width: 20%;
}

.width_30 {
  width: 30%;
}

.width_10 {
  width: 10%;
}

// Paginations
.MuiPopover-root.MuiMenu-root.MuiModal-root {
  z-index: 9999;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.MuiTablePagination-menuItem,
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows,
.MuiSelect-select {
  color: var(--main-txt-sure-grey) !important;
  font-family: 'Manrope', sans-serif !important;
  font-size: var(--main-txt-size-14) !important;
  font-weight: var(--main-font-weight-500) !important;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input {
  font-family: 'Manrope', sans-serif;
  line-height: inherit;
  cursor: pointer;
}
