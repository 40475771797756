@import '../../assets/styles/modules/mixins.scss';

.dashboard_container {
  height: calc(100vh - 6.1rem);
  background-color: var(--main-bg-white-brow-color);

  .dashboard_wrapper {
    display: flex;
    height: 100%;
    width: 100%;

    .compare_map_wrapper {
      width: 100%;
      padding: 2rem 2rem 4rem 2rem;
      position: relative;

      .compare_map_container {
        height: 100%;

        .compare_map_sections {
          display: flex;
          justify-content: space-between;
          height: 100%;

          .map_list {
            flex-basis: 49.5%;
          }
        }
      }

      .merge_map_icon {
        @include common-btn;
        position: absolute;
        top: 0rem;
        left: 50%;
        transform: translate(-50%, 0%);
        background: var(--main-bg-green-color);
        box-shadow: 0rem 0.4rem 0.4rem rgb(0 0 0 / 25%);
        @include dimensions(4.2rem, 4.2rem);
        border-radius: 100%;
        padding: 1rem;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.merge_modal_container {
  @include modal-container;

  .merge_modal_icon {
    @include dimensions(2rem, 2rem);
    margin: 0 auto;

    img {
      @include dimensions(100%, 100%);
    }
  }

  .merge_text {
    p {
      @include font-profile-14-medium;
      color: var(--main-txt-black-color);
    }
  }

  .merge_modal_btn {
    margin-top: 2rem;
    @include justify-center;

    .yes_btn {
      @include common-btn;
      background-color: var(--main-border-green-color);
      color: var(--main-txt-white-color);
      margin-right: 1.6rem;
      @include dimensions(7.6rem, 3rem);
    }

    .skip_btn {
      @include common-btn;
      @include dimensions(7.6rem, 3rem);
      border: 0.1rem solid var(--main-border-blue-green-color);
      color: var(--secondary-txt-green);
    }
  }
}
