@import '../../assets/styles/modules/mixins.scss';

.rid_list_container {
  padding: 2.4rem 4rem 4.4rem 2rem;

  .rid_header {
    @include space-between;
    margin-bottom: 2.5rem;

    label {
      @include font-profile-20-semibold;
      color: var(--main-txt-dark-grey);
    }

    span {
      display: block;
      @include dimensions(2.4rem, 2.4rem);
      cursor: pointer;

      img {
        @include dimensions(100%, 100%);
      }
    }
  }

  .country_list {
    cursor: pointer;
    @include align-center;
    margin-bottom: 1.6rem;

    li {
      &:not(:first-child) {
        @include align-center;
      }

      &:hover {
        text-decoration-line: underline;
      }

      label {
        display: block;
        @include dimensions(2rem, 2rem);

        img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        @include font-profile-16-semibold;
        color: var(--main-txt-dark-grey);
      }
    }
  }

  .search_filter_sections {
    @include space-between;

    .search_input_sections {
      flex-basis: 30%;
      @include align-center;
      @include dimensions(100%, 3.2rem);
      border-radius: 0.4rem;
      border: 0.1rem solid var(--main-border-white-grey-color);
      padding: 0rem 0.8rem;
      margin-right: 0.8rem;

      .search_icon {
        display: block;
        @include dimensions(1.6rem, 1.6rem);
        margin-right: 0.8rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        width: 100%;

        input {
          width: 100%;
          border: none;
          outline: none;
          @include font-profile-14-normal;
          background-color: transparent;

          &::placeholder {
            color: var(--main-txt-lite-grey);
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .filter_modal {
      flex-basis: 30%;
      @include center-space-between;
      justify-content: flex-end;

      .clear_filter {
        cursor: pointer;
        color: var(--main-txt-green-color);
        @include font-profile-14-semibold;
        margin-right: 1.6rem;
      }

      .filter_btn {
        @include center;
        padding: 1.2rem 0.8rem;
        border-radius: 0.4rem;
        border: 1px solid var(--main-border-white-smoke-color);
        background: var(--main-bg-white-smoke);
        cursor: pointer;

        label {
          color: var(--main-txt-dark-grey);
          @include font-profile-14-semibold;
          margin-right: 0.8rem;
          cursor: pointer;
        }

        span {
          @include dimensions(2rem, 2rem);

          img {
            width: 100%;
            height: 100%;
          }
        }

        .arrow_down {
          transition: all 0.4s ease;
          transform: rotateZ(180deg);
        }

        .arrow_up {
          transition: all 0.4s ease;
          transform: rotateZ(360deg);
        }
      }
    }
  }

  .selection_filter_section {
    margin-bottom: 1.6rem;
    @include align-center;
    border-radius: 0.4rem;
    background: var(--main-bg-white-smoke);
    padding: 0.8rem;

    li {
      width: 100%;
      flex-basis: 21%;
      margin-right: 0.7rem;

      label {
        display: block;
        @include font-profile-14-medium;
        color: var(--main-txt-dark-grey);
        margin-bottom: 0.4rem;
      }

      span {
        width: 100%;
      }
    }
  }

  .rid_list_table {
    border-radius: 0.4rem;
    border: 0.1rem solid var(--main-border-white-grey-color);
    border-spacing: 0rem;
    margin-top: 1.6rem;

    .width_15 {
      width: 15%;
    }

    .width_42 {
      text-align: start;
      width: 42%;
    }

    .ridCheckbox {
      @include custom_checkbox;
    }

    thead,
    tbody .table_tr {
      width: 100%;
      table-layout: fixed;
      display: table;
    }

    thead {
      height: 5rem;
      background-color: var(--main-bg-white-smoke);
      border-radius: 0.4rem;

      tr {
        th {
          text-transform: uppercase;
          padding: 1rem 0rem;
          @include font-profile-14-semibold;
          color: var(--main-txt-lite-grey);
          vertical-align: middle;
        }
      }
    }

    .table_body_container {
      border-top: none;
      border-radius: 0rem 0rem 0.8rem 0.8rem;
      display: block;
      overflow: auto;
      height: calc(100vh - 49.4rem);

      &.filter_expanded {
        height: calc(100vh - 65rem);
      }

      .table_tr {
        background: var(--main-bg-white-color);
        border-bottom: 0.1rem solid var(--main-border-white-lite-color);
        height: 5rem;

        .list_name {
          color: var(--main-txt-lite-blue);
          cursor: pointer;
        }

        &:hover td {
          background-color: rgba(61, 178, 103, 0.15);

          .copy_icon {
            visibility: visible;
          }
        }

        td {
          background: var(--main-bg-white-color);
          color: var(--main-txt-dark-grey);
          @include font-profile-14-semibold;
          text-transform: capitalize;

          .copy_icon {
            vertical-align: middle;
            margin-left: 1rem;
            cursor: pointer;
            visibility: hidden;
          }
        }
      }
    }

    .no_data_fond {
      @include center;
      flex-direction: column;
      height: 100%;

      label {
        img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        color: var(--main-txt-black-color);
        @include font-profile-14-medium;
      }
    }
  }

  .add_region_sections {
    @include center-space-between;
    padding: 1.6rem 0rem 1.6rem 2.4rem;

    .selected_count {
      label {
        margin-right: 1.6rem;
        text-align: center;
        display: inline-block;
        @include dimensions(3rem, 2.4rem);
        @include font-profile-14-medium;
        padding: 0.2rem 0.7rem;
        color: var(--main-txt-white-color);
        border-radius: 0.4rem;
        background: var(--main-bg-green-color);
      }

      span {
        color: var(--main-txt-dark-grey);
        @include font-profile-14-semibold;
        text-transform: capitalize;
      }
    }

    li {
      p {
        @include font-profile-14-normal;
        color: var(--main-txt-suva-grey-color);
      }

      .add_region_btn {
        @include common-btn;
        color: var(--main-txt-white-color);
        background: var(--main-bg-green-color);
      }
    }
  }
}
