@import '../../assets/styles/modules/mixins.scss';

.map_controls_container {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 0.8rem;
  z-index: 2;

  .map_control_list {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &.full_screen:hover,
    &.layer_opacity:hover,
    &.zoom_out:hover,
    &.zoom_in:hover {
      .control_hover {
        display: flex;
      }
    }

    .map_control_icons {
      cursor: pointer;
      @include dimensions(2.4rem, 2.4rem);

      img {
        @include dimensions(100%, 100%);
      }
    }

    .control_hover {
      display: none;
      position: absolute;
      right: 3.8rem;
      top: 0.2rem;
      padding: 0.4rem, 1.6rem;
      border-radius: 0.4rem;
      background: rgba(3, 3, 3, 0.75);
      align-items: center;
      justify-content: center;
      @include dimensions(9.3rem, 2.8rem);

      label {
        @include font-profile-12-medium;
        color: var(--main-txt-white-color);
      }

      span {
        position: absolute;
        right: 0rem;
        top: 0.7rem;

        &::before {
          clip-path: polygon(0 0, 0% 100%, 100% 50%);
          background: rgba(3, 3, 3, 0.75);
          content: '';
          position: absolute;
          @include dimensions(1.2rem, 1.5rem);
        }
      }
    }
  }
}
