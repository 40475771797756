.request_container {
  margin: 2.4rem 1.6rem 1rem 1.6rem;
}

.request_header {
  display: flex;
  width: 100%;
  border: 0.1rem solid #3db267;
  border-radius: 0.5rem;
}

.request-btn {
  display: flex;
  justify-content: center;
  border-radius: 0.4rem;
  align-items: center;

  cursor: pointer;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  background-color: #f5f5f5;
  color: var(--main-txt--sat-lite-sure-grey);
}

.request-btn.selected {
  background-color: #3db267;
  color: var(--main-txt-white-color) !important;
}

.request-btn img {
  display: flex;
  width: 1.6rem;
  height: 1.6rem;
  color: var(--main-txt-white-color);
  margin-right: 0.5rem;
}

.data,
.region {
  height: 3.125rem;
  width: 100%;
}

.data-product-selector {
  padding-right: 1.6rem;
}

.data-product-wrapper {
  padding: 0rem 0rem 0rem 1.6rem;
}

.available-product {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.available-product label {
  color: #696b72;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-20);
}

.available-product span {
  font-size: var(--main-txt-size-12);
  color: #696b72;
  padding-top: 1.5rem;
}

.product-card-panel {
  height: calc(100vh - 28rem);
  padding: 1.35rem 1rem 1rem 0rem;
  overflow-y: auto;
}

.select-region {
  color: #2f80ed !important;
  text-decoration: underline;
  font-weight: var(--main-font-weight-500) !important;
  cursor: pointer;
  padding: 0rem 0.3rem;
}

.product-card-section {
  margin-bottom: 1.5rem;
}

.cards-section {
  overflow: auto;
  padding-right: 1rem;
  padding-top: 1.5rem;
}

.card-section-header-all {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-20);
  color: rgba(105, 107, 114, 1);
}

.card-section-header {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-20);
  color: rgba(105, 107, 114, 1);
}

.cards-wrapper {
  border: 0.05rem solid #c7c7cc;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
}

.cards-wrapper:not(.selected):hover {
  background: rgba(61, 178, 103, 0.1);
}

.cards-wrapper:not(:last-child) {
  margin-bottom: 1rem;
}

.cards-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.cards-header label {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-20);
  color: #42444a;
}

.selected {
  background: linear-gradient(
      0deg,
      rgba(61, 178, 103, 0.1) 0%,
      rgba(61, 178, 103, 0.1) 100%
    ),
    #fff;
  border: none;
}

.product-desc {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-400);
  line-height: var(--main-line-height-16);
  color: #696b72;
  cursor: pointer;
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.read-more {
  color: var(--sparta-sparta-dark-1, #3db267);
  font-size: var(--main-txt-size-12);
  display: flex;
  padding-top: 1rem;
  justify-content: right;
}

.data-product-select {
  box-shadow: none !important;
  margin-bottom: 0.8rem;
  height: 4rem;
}

.data-product-select__indicator-separator {
  display: none !important;
}

.data-product-select__control--is-focused {
  border: 0.1rem solid #c7c7cc !important;
  box-shadow: none !important;
}

.data-product .react-select__control:focus-within,
.data-product .react-select__control:hover {
  border: 0.1rem solid #3db267 !important;
  caret-color: #3db267 !important;
}

.data-product-select__placeholder,
.data-product-select__single-value,
.data-product-select__menu-list {
  font-size: var(--main-txt-size-14);
}

.data-product-select__control
  .data-product-select__control--is-focused
  .data-product-select__control--menu-is-open {
  color: #000;
}

.cards-header input[type='checkbox'] + span:before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2rem solid #3db267 !important;
  background-color: white;
  border-radius: 0.4rem;
  vertical-align: middle;
  cursor: pointer;
}

.cards-header input[type='checkbox']:checked + span:before {
  background-color: #3db267 !important;
  border-color: #3db267 !important;
}

.available-products-header {
  display: flex;
}

.available-products-info {
  margin: 0.5rem 0rem 0rem 0.5rem;
  cursor: pointer;
}

.main {
  display: block;
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
}

.data_checkbox {
  visibility: hidden;
}

.mark {
  position: absolute;
  top: 0;
  left: 0;
}

.main input:checked ~ .mark {
  border-radius: 0.4rem;
}

.mark:after {
  content: '';
  position: absolute;
  display: none;
}

.main input:checked ~ .mark:after {
  display: block;
}

.main .mark:after {
  left: 0.7rem;
  bottom: 0.6rem;
  width: 0.3rem;
  height: 0.7rem;
  border: solid white;
  border-width: 0 0.2rem 0.2rem 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.available-prodcuts-tooltip {
  position: absolute;
  left: 32%;
  visibility: hidden;
  z-index: 2;
}

.top-triangle {
  width: 0;
  height: 0;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  border-bottom: 0.8rem solid black;
  margin-left: 25%;
}

.available-prodcuts-tooltip-content {
  border-radius: 0.8rem;
  padding: 1.6rem;
  background-color: rgba(0, 0, 0, 0.92);
}

.available-prodcuts-tooltip-content p {
  color: var(--main-txt-white-color);
  font-size: var(--main-txt-size-12);
}

.available-products-info:hover + .available-prodcuts-tooltip {
  visibility: visible;
  transition-duration: 0.5s;
}
