@import '../../assets/styles/modules/mixins.scss';

.profile_container {
  @include common-setting-wrapper;

  .user_details {
    @include center-space-between;

    .user_edit {
      flex-basis: 60%;
      padding-right: 5.2rem;
      height: calc(100vh - 21.8rem);
      border-right: 0.1rem solid var(--main-border-lite-grey-color);
      overflow-y: auto;

      .common_user_list {
        margin-bottom: 1.6rem;

        &.flex_width {
          flex-basis: 48%;
        }

        label {
          @include font-profile-16-medium;
          color: var(--main-txt-suva-grey-color);
          display: block;
          margin-bottom: 0.8rem;
        }

        span {
          display: block;

          &.user_email {
            @include font-profile-16-medium;
            color: var(--main-txt-suva-grey-color);
            border: 0.1rem solid var(--main-border-grey-lite-color);
            border-radius: 0.4rem;
            padding: 0.8rem 1.6rem;
            background-color: var(--main-bg-grey-color);
          }

          .user_input {
            @include input-text;
            color: var(--main-txt-dull-navy-color);
          }
        }
      }

      .user_first_last_name {
        @include space-between;
      }

      .save_btn {
        @include common-btn;
        margin: 0 auto;
        margin-top: 2rem;
        padding: 0.8rem 3.6rem;
        color: var(--main-txt-white-color);
        background: var(--main-bg-green-color);
      }
    }

    .profile_name_short {
      flex-basis: 40%;

      .user_character {
        background-color: var(--main-bg-white-lite-color);
        @include dimensions(20rem, 20rem);
        border-radius: 100%;
        margin: 0 auto;
        @include center;

        label {
          font-size: var(--main-txt-size-65);
          font-weight: var(--main-font-weight-600);
          line-height: var(--main-line-height-78);
          color: var(--main-txt-green-gray);
        }
      }
    }
  }
}

.profile_modal_container {
  @include modal-container;

  p {
    @include font-profile-14-medium;
    color: var(--main-txt-black-color);
  }

  .profile_modal_btn {
    margin-top: 2rem;
    @include justify-center;

    .save_btn {
      @include common-btn;
      background-color: var(--main-border-green-color);
      color: var(--main-txt-white-color);
      margin-right: 1.6rem;
      @include dimensions(7.6rem, 3rem);
    }

    .discard_btn {
      @include common-btn;
      @include dimensions(7.6rem, 3rem);
      border: 0.1rem solid var(--main-border-blue-green-color);
      color: var(--secondary-txt-green);
    }
  }
}
