.react-modal-header {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #bdbdbd;
}

.react-popup-modal {
  top: 50%;
  left: 50%;
  right: 'auto';
  bottom: 'auto';
  margin-right: -50%;
  transform: 'translate(-50%, -50%)';
  width: '80%';
  height: '60vh';
}

.close-content-wrapper {
  display: flex;
  margin: 0px 10px;
  cursor: pointer;
  padding: 5px;
}

.close-content-wrapper:hover {
  background: #8080803b;
  border-radius: 5px;
}

.react-modal-body {
  overflow-y: auto;
  height: 60vh;
}

.react-modal-body .body-data {
  padding: 24px;
}

.copy {
  background-color: transparent;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy:hover {
  background: #8080803b;
}

.copy.copied {
  background-color: none;
  cursor: default;
}
