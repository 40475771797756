@import '../../assets/styles/modules/mixins.scss';

.map_comparison_wrapper {
  @include dimensions(100%, 100%);
  border-radius: 0.8rem;
  @include direction_column;
  justify-content: space-around;
  background-color: var(--main-txt-white-smoke);

  .map_comparison_container {
    flex-basis: 100%;
    position: relative;

    .ol-viewport {
      border-radius: 0.8rem;
    }

    .map_sections {
      @include dimensions(100%, 100%);

      .merge_multi_layer_product_legend {
        position: absolute;
        bottom: 2rem;
        right: 4rem;
        z-index: 1;
        width: 96%;
        @include space-between;

        .layer_legend {
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
