.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.banner-sections {
  flex-basis: 70%;
  position: relative;
}

.banner-img {
  width: 100%;
  height: 100%;
}

.banner-img img {
  width: 100%;
  height: 100%;
}

.sparta-logo-sections {
  position: absolute;
  inset: 4rem auto auto 8rem;
}

.sparta-logo {
  width: 15rem;
  height: 15rem;
}

.sparta-logo img {
  width: 100%;
  height: 100%;
}

.heading {
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-24);
  font-weight: var(--main-font-weight-700);
  text-align: center;
  flex-basis: 10%;
}

.form-container {
  flex-basis: 30%;
  width: 56rem;
  background: white;
  padding: 4.5% 5.5%;
}

.form-group {
  margin-top: 1.6rem;
}

.industry-role {
  margin-top: 4rem;
}

.form-group .group-title {
  display: block;
  color: var(--main-txt-suva-grey-color);
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  margin-bottom: 0.7rem;
  position: relative;
}

/* .form-group label sup, */
sup {
  color: #df0000;
}

.name-wrapper {
  display: flex;
  justify-content: space-between;
}

.name-wrapper .form-group {
  flex-basis: 45%;
}

.input-feild {
  position: relative;
  display: block;
  width: 100%;
}

.login-input-feild .input-text {
  height: 4.4rem;
  width: 100%;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
  font-weight: var(--main-font-weight-600);
  color: var(--main-txt-dark-grey);
  border: none;
  caret-color: #3db267;
  border-bottom: 0.1rem solid #3db267 !important;
  background: rgba(61, 178, 103, 0.1);
}

.input-text:active,
.input-text:focus-visible {
  outline: 0rem;
}

.password-checker {
  display: flex;
  flex-wrap: wrap;
}

.password-checker li {
  flex-basis: 50%;
}

.password-checker .password-success label svg path {
  stroke: var(--main-txt-green);
}

.password-checker .password-success span {
  color: var(--main-txt-green);
}

.password-checker li span {
  color: var(--main-txt-lite-grey);
  font-size: var(--main-txt-size-10);
  margin-left: 0.7rem;
}

.grey {
  border-bottom: 0.1rem solid var(--main-border-grey-color);
}

.red {
  border-bottom: 0.1rem solid #eb5757 !important;
}

.input-text::placeholder {
  font-size: var(--main-txt-size-16);
  color: #bdbdbd;
  font-weight: var(--main-font-weight-500);
}

.register-button {
  margin: 3.2rem 0rem 2rem;
}

.btn-register {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-bg-green-color);
  height: 4.4rem;
  color: var(--main-txt-white-color);
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-20);
}

.btn-register label {
  cursor: pointer;
}

.signIn-link {
  text-align: center;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
}

.signIn-link label {
  color: var(--main-txt-grey-color);
  padding-right: 1rem;
}

.signIn-link a {
  color: #42444a !important;
  cursor: pointer;
  font-weight: var(--main-font-weight-700);
}

.passwordIcon {
  cursor: pointer;
  position: absolute;
  inset: 1.2rem 1rem auto auto;
  width: 2rem;
  height: 2rem;
}

.mail-text {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  display: block;
  text-align: center;
  line-height: var(--main-line-height-22);
  color: var(--main-txt-grey-color);
  margin-bottom: 3rem;
}

.select-sections:not(:last-child) {
  margin-bottom: 1.6rem;
}

.select-sections .react-select__control {
  border: none !important;
  border-radius: 0;
  border-bottom: 0.1rem solid #000 !important;
}

.select-sections .react-select__control:focus-within,
.select-sections .react-select__control:hover {
  caret-color: #3db267;
  border-bottom: 0.1rem solid #3db267 !important;
  background: rgba(61, 178, 103, 0.1);
}

.select-sections label {
  font-weight: var(--main-font-weight-600);
  display: block;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
  color: var(--main-txt-suva-grey-color);
  margin-bottom: 0.7rem;
}

.poweredby {
  text-align: center;
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: #283988;
}

.satsure-logo {
  width: 13.8rem;
  margin: 0 auto;
}

.satsure-logo img {
  width: 100%;
  height: 100%;
}

/* Rect Select Picker */

.react-select__control {
  border: 0.1rem solid #828282 !important;
  border-color: #828282 !important;
  box-shadow: none !important;
  margin-bottom: 0.8rem;
  height: 4.4rem;
}

.react-select__value-container,
.react-select__indicator {
  cursor: pointer !important;
}

.react-select__indicator-separator {
  margin-bottom: 0rem !important;
  margin-top: 0rem !important;
  width: 0rem !important;
}

.react-select__indicator {
  color: var(--main-txt-dull-navy-color) !important;
}

.selectCategory__option--is-selected {
  background-color: green !important;
}

.react-select__single-value {
  color: var(--main-txt-dull-navy-color) !important;
}

.react-select__option:active,
.react-select__option:focus {
  background-color: #c4c4c463;
}

.react-select__option--is-selected {
  background-color: #c4c4c463;
}

.react-select__option {
  background-color: white !important;
  color: #333333 !important;
  font-size: var(--main-txt-size-14) !important;
  font-weight: var(--main-font-weight-500) !important;
}

.react-select__option--is-selected {
  background-color: transparent !important;
  color: #0db02b !important;
  font-weight: var(--main-font-weight-600);
}

.react-select__option--is-focused {
  background-color: #0db02b !important;
  color: var(--main-txt-white-color) !important;
}

.react-select__menu {
  z-index: 3 !important;
}

.react-select__placeholder {
  color: #696b72 !important;
}

.login-button {
  margin: 1.6rem 0rem 2rem;
}

.btn-login {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-bg-green-color);
  height: 4.4rem;
  color: var(--main-txt-white-color);
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-20);
}

.btn-login label {
  cursor: pointer;
}

.login-input-feild {
  position: relative;
  display: block;
  width: 100%;
}

.error-text {
  display: block;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-10);
  line-height: var(--main-line-height-12);
  color: #eb5757;
  margin-top: 0.5rem;
  height: 1.2rem;
}

.error-text.visible {
  visibility: visible;
}

.error-text.hidden {
  visibility: hidden;
}

.forgot-password-text-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.forgot-password-text {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-13);
  line-height: var(--main-line-height-18);
  color: var(--main-txt-dull-navy-color) !important;
}

.google-login {
  margin: 2rem 0rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.google-login button {
  font-size: var(--main-txt-size-16) !important;
  line-height: var(--main-line-height-19) !important;
  color: #000000 !important;
  width: 100%;
  border-radius: 0.8rem !important;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.5rem 1.5rem !important;
}

.common-login-field-label {
  color: var(--main-txt--sat-lite-sure-grey);
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-600);
}

.login-content {
  flex-basis: 60%;
}

.select_red.react-select__control {
  border: 0.1rem solid red !important;
}

.google-login-wrapper label {
  margin-left: 1rem;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  cursor: pointer;
}

.signup-link {
  text-align: center;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  margin-top: 2.4rem;
  font-weight: var(--main-font-weight-500);
}

.signup-link label {
  color: var(--main-txt-grey-color);
}

.signup-link a {
  color: #42444a !important;
  cursor: pointer;
  font-weight: var(--main-font-weight-700);
}

.gamil-valid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.final-sections .signup-link {
  margin: 3rem 0rem;
}

.final-continue h1 {
  margin-bottom: 3rem;
}

.gamil-valid span {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-grey-color);
  margin-left: 1.5rem;
}

.continue-mail {
  margin: 6rem 0rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.continue-mail label {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.2rem;
}

.logged-in {
  border: 0.1rem solid #e0e0e0;
  padding: 2.2rem;
}

.continue-mail label img {
  width: 100%;
  height: 100%;
}

.continue-mail span {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-grey-color);
}

.edit-email {
  position: absolute;
  right: 0rem;
  bottom: 1rem;
}

.common-login-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#container.haAclf {
  padding: 0rem 0rem !important;
  width: 100% !important;
}
