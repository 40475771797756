.draft-container {
  width: 100%;
  height: calc(100vh - 61px);
}

.draft-wrapper {
  width: 100%;
  height: calc(100vh - 61px);
  background: white;
}

.draft-wrapper.active {
  width: calc(100% - 37.9rem);
  right: 0rem;
  position: absolute;
  height: calc(100vh - 61px);
}

.draft-header {
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.draft-header-wrapper {
  display: flex;
  align-items: center;
}
.draft-header-wrapper h1 {
  font-weight: 600;
  color: #42444a;
  font-size: 16px;
  line-height: 22px;
}
.draft-header-wrapper span {
  margin-left: 20px;
  font-size: 14px;
  color: #696b72;
  font-weight: 500;
}
.draft-button-wrapper .btn-cancel {
  border-radius: 4px;
  width: 76px;
  height: 32px;
  font-size: 14px;
  border: 1px solid #3db267;
  color: #3db267;
  background: #ffff;
  cursor: pointer;
}

.drafts-search {
  width: 245px;
  height: 26px;
  border: 1px solid #c7c7cc;
}

.draft-button-wrapper .btn-edit {
  margin-left: 16px;
  width: 58px;
  height: 32px;
  border-radius: 4px;
  background: #3db267;
  color: #ffff;
  font-size: 14px;
  cursor: pointer;
}

.draft-body {
  width: 100%;
  height: calc(100vh - 126px);
  overflow: auto;
}

.draft-body-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.btn.draft-delete {
  background: #eb5757;
  color: #ffff;
  width: 76px;
  height: 32px;
  border-radius: 5px;
}
.hover-draft-icons img {
  padding: 0.2rem 0.5rem;
}
