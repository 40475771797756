// Mixin for centering both horizontally and vertically
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Mixin for aligning items vertically at the center
@mixin align-center {
  display: flex;
  align-items: center;
}

// Mixin for justifying content at the center horizontally
@mixin justify-center {
  display: flex;
  justify-content: center;
}

// Mixin for justifying content at the end horizontally
@mixin justify-end {
  display: flex;
  justify-content: flex-end;
}

// Mixin for spacing items evenly between each other
@mixin space-between {
  display: flex;
  justify-content: space-between;
}

// Mixin for spacing items evenly around each other
@mixin space-around {
  display: flex;
  justify-content: space-around;
}

// Mixin for setting flex direction to column
@mixin direction_column {
  display: flex;
  flex-direction: column;
}

// Mixin for setting flex direction to row
@mixin direction_row {
  display: flex;
  flex-direction: row;
}

// Mixin for centering items vertically and spacing them evenly horizontally
@mixin center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Mixin for setting text alignment to center
@mixin text-align-center {
  text-align: center;
}

// Mixin for setting dimensions (width and height)
@mixin dimensions($width, $height) {
  width: $width;
  height: $height;
}

// Mixins for various font profiles

// Mixin for a medium font profile with size 10
@mixin font-profile-10-medium {
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-500);
  line-height: normal;
}

// Mixin for a medium font profile with size 12
@mixin font-profile-12-medium {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
  line-height: normal;
}

// Mixin for a semibold font profile with size 12
@mixin font-profile-12-semibold {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  line-height: normal;
}

// Mixin for a normal font profile with size 14
@mixin font-profile-14-normal {
  font-size: var(--main-txt-size-14);
  line-height: normal;
}

// Mixin for a medium font profile with size 14
@mixin font-profile-14-medium {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: normal;
}

// Mixin for a semibold font profile with size 14
@mixin font-profile-14-semibold {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  line-height: normal;
}

// Mixin for a normal font profile with size 16
@mixin font-profile-16-normal {
  font-size: var(--main-txt-size-16);
  line-height: normal;
}

// Mixin for a medium font profile with size 16
@mixin font-profile-16-medium {
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-500);
  line-height: normal;
}

// Mixin for a semibold font profile with size 16
@mixin font-profile-16-semibold {
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-600);
  line-height: normal;
}

// Mixin for a semibold font profile with size 20
@mixin font-profile-20-normal {
  font-size: var(--main-txt-size-20);
  line-height: normal;
}

// Mixin for a semibold font profile with size 20
@mixin font-profile-20-semibold {
  font-size: var(--main-txt-size-20);
  font-weight: var(--main-font-weight-600);
  line-height: normal;
}

// Mixin for a medium font profile with size 22
@mixin font-profile-22-medium {
  font-size: var(--main-txt-size-22);
  font-weight: var(--main-font-weight-500);
  line-height: normal;
}

// Mixin for a semibold font profile with size 22
@mixin font-profile-22-semibold {
  font-size: var(--main-txt-size-22);
  font-weight: var(--main-font-weight-600);
  line-height: normal;
}

// Mixin for a medium font profile with size 24
@mixin font-profile-24-medium {
  font-size: var(--main-txt-size-24);
  font-weight: var(--main-font-weight-500);
  line-height: normal;
}

// Mixin for a semibold font profile with size 24
@mixin font-profile-24-semibold {
  font-size: var(--main-txt-size-24);
  font-weight: var(--main-font-weight-600);
  line-height: normal;
}

// Mixin for a medium font profile with size 40
@mixin font-profile-40-medium {
  font-size: var(--main-txt-size-40);
  font-weight: var(--main-font-weight-500);
  line-height: normal;
}

// Mixin for common button styles
@mixin common-btn {
  @include center;
  cursor: pointer;
  padding: 1rem 2.4rem;
  background-color: transparent;
  @include font-profile-14-medium;
  border-radius: 0.4rem;

  &:disabled {
    cursor: alias;
    color: var(--main-txt-white-color);
    background-color: var(--main-bg-white-grey-color);
  }
}

// Mixin for common setting wrapper styles
@mixin common-setting-wrapper {
  padding: 4rem;

  .header {
    h1 {
      @include font-profile-22-medium;
      color: var(--main-txt-dull-navy-color);
    }

    hr {
      border: 1px solid #e0e0e0;
      margin: 1.5rem 0;
    }
  }
}

/* Input Field */
@mixin input-text {
  width: 100%;
  @include font-profile-16-normal;
  color: var(--main-txt-black-color);
  padding: 0.9rem 1.6rem;
  border: 0.1rem solid var(--main-border-lite-grey-color);
  border-radius: 0.4rem;

  &.warning {
    border: 0.1rem solid var(--main-border-red-color) !important;
  }

  &:focus-visible {
    outline: 0.1rem solid var(--main-border-lite-grey-color);
  }

  &:disabled {
    cursor: no-drop;
    background-color: var(--main-bg-white-lite-color) !important;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// Error Text
@mixin error_text {
  display: block;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-10);
  line-height: var(--main-line-height-12);
  color: var(--Syntax-Red);
  margin-top: 0.5rem;
  height: 1.2rem;

  &.visible {
    visibility: visible;
  }

  &.hidden {
    visibility: hidden;
  }
}

// Custom Checkbox
@mixin custom_checkbox {
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: inline-block;

  .checkbox_item {
    visibility: hidden;
  }

  input[type='checkbox'] + span:before {
    content: '';
    display: inline-block;
    @include dimensions(1.5rem, 1.5rem);
    border: 0.2rem solid var(--main-border-sure-grey) !important;
    background-color: var(--main-bg-white-color);
    border-radius: 0.4rem;
    vertical-align: middle;
    cursor: pointer;
  }

  input[type='checkbox']:checked + span:before {
    background-color: var(--main-bg-green-color) !important;
    border-color: var(--main-border-green-color) !important;
  }

  input:checked ~ .checkbox_mark {
    border-radius: 0.4rem;

    &:after {
      display: block;
    }
  }

  .checkbox_mark {
    position: absolute;
    top: 0;
    left: 0;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 0.7rem;
      bottom: 0.6rem;
      width: 0.3rem;
      height: 0.7rem;
      border: solid var(--main-border-white-color);
      border-width: 0 0.2rem 0.2rem 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }
}

// Mixin for modal overlay styles
@mixin modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

// Mixin for modal content styles
@mixin modal-content {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background-color: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 8px;
  outline: none;
  padding: 0px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

// Mixin for modal container styles
@mixin modal-container {
  background: var(--main-bg-white-color);
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.4rem #00000040;
  padding: 2.5rem;
}
