@import '../../assets/styles/modules/mixins.scss';

.notFoundWrapper {
  @include center;
  @include dimensions(100%, 100vh);

  .notFoundContainer {
    @include direction_column;
    align-items: center;
  }

  .notFoundHeader {
    @include font-profile-24-medium;
    color: var(--main-txt-dark-grey);
  }

  .notFoundSubHeader {
    @include font-profile-14-medium;
    color: var(--main-txt-sure-grey);
    margin: 10px 0px 30px 0px;
  }

  .buttonHome {
    @include common-btn;
    background: var(--main-txt-green);
    color: #ffff;
  }
}
