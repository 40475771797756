/*confirm req modal*/
.conf-req-sections {
  padding: 1.6rem;
}

.conf-req-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #f2f2f2;
  margin-bottom: 2.4rem;
}

.conf-req-header label {
  color: #42444a;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.conf-req-para {
  display: flex;
  justify-content: center;
  color: #42444a;
  font-size: var(--main-txt-size-14);
  margin: 2.4rem 0rem 4rem;
  font-weight: var(--main-font-weight-400);
}

.conf-img {
  display: flex;
  justify-content: center;
}

.conf-req-btn {
  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid #f2f2f2;
  padding: 1.6rem;
}

.conf-req-btn :nth-child(2) {
  display: flex;
}

.conf-req-btn .discard {
  color: #3db267;
  border: 0.1rem solid #3db267;
  margin-right: 0.8rem;
  width: 11.1rem;
  padding: 0.5rem 1rem;
}

.conf-req-btn .request {
  color: var(--main-txt-white-color);
  background-color: #3db267;
  padding: 0rem 3.3rem;
}

.conf-req-close {
  cursor: pointer;
}

.view-project-button {
  display: flex;
}

.view-project-button button {
  color: var(--main-txt-green);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  text-decoration-line: underline;
  background: transparent;
  cursor: pointer;
}

.input-div-container {
  display: flex;
  justify-content: space-between;
}

.delivery-manager-img {
  display: flex;
  width: 18px;
  height: 18px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.request-button.loaders .common-btn-loader {
  margin: 0 auto;
  width: 1rem;
  height: 1rem;
}

.remove-icon {
  position: relative;
}

.remove-icon img:hover ~ .remove-icon-tooltip {
  display: block;
}

.remove-icon-tooltip {
  display: none;
  position: absolute;
  right: -0.7rem;
  bottom: -2.8rem;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
}

.remove-icon-tooltip::before {
  -webkit-clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  background: rgba(0, 0, 0, 0.5);
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  content: '';
  height: 9px;
  position: absolute;
  width: 15px;
  top: -9px;
  right: 12px;
}

.input-div {
  position: relative;
}

.input-div-container {
  position: relative;
}

.error_confirm_request {
  color: red !important;
  font-size: 1.1rem !important;
  text-decoration: none !important;
  cursor: default !important;
}

.required::after {
  content: ' *';
  color: #e74c3c;
}

.delivery-manager-img {
  display: inline-block;
  cursor: pointer;
}

.info-popup {
  display: none;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 10px;
  top: -36px;
  left: -53px;
  width: 214px;
}

.info-content {
  font-size: var(--main-txt-size-14);
  color: #fff;
}

.triangle-top {
  position: absolute;
  top: 50%;
  right: -10px;
  margin-top: -22px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgba(0, 0, 0, 0.8);
}

.delivery-manager-img:hover + .info-popup {
  display: block;
}
.request-date-picker {
  width: 100%;
}

.custom-date-input {
  display: flex;
  justify-content: space-between;

  width: 100% !important;
}
.request-datepicker-wrapper {
  width: 100%;
}
.request-datepicker-popper {
  left: 0 !important;
}
