.react-datepicker__input-container {
  position: relative;
  display: flex;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  right: 0;
  padding: 0;
  z-index: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-datepicker-body .react-datepicker-wrapper {
  width: 50%;
}

.request-datepicker-body .react-datepicker-wrapper .request-datepicker {
  width: 100%;
}

.react-datepicker__view-calendar-icon input {
  padding: 0;
}
