.heading-wrapper {
  display: flex;
  padding: 20px 50px;
}

.setting-header {
  font-size: var(--main-txt-size-22);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-30);
  color: var(--main-txt-dark-charcoal-color);
}

.image-wrapper {
  width: 20px;
  height: 25px;
  cursor: pointer;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
}

/* sidebar list */

.sidebar-tab-list {
  padding-left: 0px !important;
}

.sidebar-docs-list.active label {
  color: var(--main-txt-white-color);
}

.sidebar-docs-list.active {
  background: var(--main-bg-green-color);
  color: var(--main-txt-white-color);
}

.api-managment {
  padding-left: 0px !important;
  visibility: visible;
}

.api-managment.collapse {
  max-height: 0px;
  visibility: collapse;
}

.industry-managment {
  padding-left: 0px !important;
  visibility: visible;
}

.industry-managment.collapse {
  max-height: 0px;
  visibility: collapse;
}

.list-image-wrapper.rotate {
  transform: rotate(90deg);
  margin-bottom: 25px;
  margin-left: 15px;
  padding-right: 0px;
}

.child-image-wrapper {
  padding-right: 10px;
}

.child-image-wrapper.rotate {
  transform: rotate(90deg);
  margin-top: 15px;
}

.api-managment .sidebar-docs-list.child {
  padding-left: 65px;
}

.api-managment .sidebar-docs-list {
  padding-left: 50px;
}

.industry-managment .sidebar-docs-list.child {
  padding-left: 65px;
}

.industry-managment .sidebar-docs-list {
  padding-left: 27px;
}

.sidebar-docs-list {
  height: 44px;
  display: flex;
  align-items: center;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  cursor: pointer;
  color: var(--main-txt-dark-charcoal-color);
}

.child-sidebar-docs-list {
  padding-left: 48px;
  height: 44px;
  line-height: var(--main-line-height-44);
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  cursor: pointer;
  display: flex;
  color: var(--main-txt-dark-charcoal-color);
}

.child-sidebar-docs-list:not(.active):hover {
  background: #cadddb;
}

.sidebar-docs-list:not(.active):hover {
  background: #c2ebca;
}

.list-image-wrapper {
  padding-left: 25px;
  padding-right: 10px;
}

.sidebar-docs-list label {
  cursor: pointer;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
  color: #333333;
}

.sidebar-docs-list .label-text {
  padding-left: 50px;
  cursor: pointer;
}

.ag-input {
  padding-left: 4rem;
}

.food-comm-list {
  padding-left: 4rem;
}
