.projects-comp-content-wrapper {
  padding: 1.4rem;
}

.projects-comp-form-group input {
  width: 100%;
  height: 3.2rem;
  padding: 0 !important;
  border: 0.1rem solid #c7c7cc;
  border-radius: 0.4rem;
  margin-bottom: 1.6rem;
  text-indent: 1rem;
}

.projects-comp-form-group input:focus {
  border: 0.1rem solid #c7c7cc;
  outline: none;
}

.project-tree-container {
  max-height: calc(100vh - 19rem);
  overflow-y: auto;
}

.view-all {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-12);
  line-height: 1.4rem;
  color: #2f80ed;
  float: right;
  margin: 1.6rem 0rem;
  cursor: pointer;
}

.date-wrapper {
  position: relative;
  padding-top: 0.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.date-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 0.5rem 0.6rem;
  margin-left: 1rem;
  border-radius: 0.4rem;
  transition: background-color 0.3s ease;
}

.date-item.back_gound {
  background-color: rgba(194, 235, 202, 0.4);
}

.date-item.remove_cursor {
  cursor: alias;
}

.date-item.back_gound.remove_cursor {
  background-color: var(--main-bg-white-grey-color);
}

.date-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.date-text {
  flex: 1;
  color: #4f4f4f;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  margin: 0rem 0.5rem;
}

.date_bg_color {
  color: #c7c7cc;
}

.progress-text {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  color: #06776c;
}

.treeview-line {
  width: 2.4rem;
  height: 0.07rem;
  background-color: #c7c7cc;
}

.no-request {
  display: block;
  color: rgb(79, 79, 79);
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-12);
  margin-top: 0.7rem;
  margin-left: 4.5rem;
}

.projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.create_new_project {
  color: #2f80ed !important;
  cursor: pointer;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-12);
}

.date-checkbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn.compare_btn {
  color: #3db267;
  padding: 0.7rem 1.5rem;
  font-size: var(--main-txt-size-12);
  border: 0.1rem solid #3db267;
}

.btn.compare_btn.bg {
  color: #fff;
  background-color: #3db267;
}

.btn.compare_btn img {
  margin-left: 1rem;
}

.btn.compare_btn:disabled {
  color: #fff;
  border: 1px transparent;
}

/* Custom Checkbox */
.custom_checkbox {
  display: block;
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
}

.checkbox_item {
  visibility: hidden;
}

.checkbox_mark {
  position: absolute;
  top: 0;
  left: 0;
}

.custom_checkbox input:checked ~ .checkbox_mark {
  border-radius: 0.4rem;
}

.checkbox_mark:after {
  content: '';
  position: absolute;
  display: none;
}

.custom_checkbox input:checked ~ .checkbox_mark:after {
  display: block;
}

.custom_checkbox .checkbox_mark:after {
  left: 0.7rem;
  bottom: 0.6rem;
  width: 0.3rem;
  height: 0.7rem;
  border: solid white;
  border-width: 0 0.2rem 0.2rem 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.custom_checkbox input[type='checkbox'] + span:before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2rem solid #2f80ed !important;
  background-color: white;
  border-radius: 0.4rem;
  vertical-align: middle;
  cursor: pointer;
}

.custom_checkbox input[type='checkbox']:checked + span:before {
  background-color: #2f80ed !important;
  border-color: #2f80ed !important;
}
