@import '../../assets/styles/modules/mixins.scss';

.user_add_modal {
  @include space-between;
  padding: 2rem;
  border-bottom: 0.1rem solid var(--main-border-lite-grey-color);

  p {
    @include font-profile-16-semibold;
    color: var(--main-txt-dark-grey);
  }

  span {
    @include dimensions(1.8rem, 1.8rem);
    cursor: pointer;
  }
}

.user_modal_body {
  padding: 3.5rem 1.5rem 1.5rem 1.5rem;
  overflow-y: auto;
  max-height: calc(100vh - 18rem);
  overflow-x: hidden;

  .user_new_container {
    @include space-between;
    margin-bottom: 1.8rem;

    .user_new_name {
      @include direction_column;
      width: 45%;
    }
  }

  .user_new_email {
    @include direction_column;
    margin-bottom: 1.8rem;

    .add_role {
      width: 100%;
      font-size: var(--main-txt-size-14);
    }
  }

  .common_input {
    input {
      @include input-text;
      @include font-profile-14-normal;
      @include align-center;
      padding: 0.6rem 0px 0.7rem 1.6rem;
      border-radius: 0.4rem;
      border: 0.1rem solid var(--main-border-white-grey-color);
      background: var(--main-bg-white-color);
      width: 100%;
    }
  }

  .common_user_email {
    input {
      @include input-text;
      @include font-profile-14-normal;
      padding: 0.7rem 0px 0.7rem 1.6rem;
      align-items: center;
      border: 0.1rem solid var(--main-border-white-grey-color);
      background: var(--main-bg-white-color);
      width: 100%;
    }

    .edit_email_input {
      background-color: var(--main-bg-grey-color);
      pointer-events: none;
    }
  }

  label {
    @include font-profile-14-medium;
    color: var(--main-txt-sure-grey);
    margin-bottom: 1.8rem;
  }
}

.create_container {
  @include justify-end;
  padding: 2.5rem 2rem;

  .add_new_button {
    @include common-btn;
    padding: 0.7rem 3rem;
    background: var(--main-txt-green);
    color: var(--main-txt-white-color);
  }

  .add_new_cancel {
    @include common-btn;
    padding: 0.7rem 3rem;
    color: var(--main-txt-green);
  }
}

// Media queries for responsiveness
@media screen and (max-width: 768px) {
  .user_modal_body {
    padding: 2rem;
  }

  .common_input input,
  .common_user_email input {
    width: 100%;
  }

  .user_new_email .add_role {
    width: 100%;
  }
}
