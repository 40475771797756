@import '../../assets/styles/modules/mixins.scss';

.data_catalog_wrapper {
  width: calc(100vw - 9rem);
  padding: 2.5rem 0rem 2.5rem 5.6rem;

  .filter_field {
    @include space-between;
    margin: 0rem 8rem 1rem 0rem;

    @media screen and (max-width: 1025px) {
      margin: 0rem 7.5rem 1rem 0rem;
    }

    .filter_tabs {
      display: flex;

      .filter_tags_btns {
        @include common-btn;
        padding: 0.8rem 2.4rem;
        border: 0.1rem solid var(--main-border-green);
        background: var(--main-bg-white-color);
        color: var(--main-txt-green);
        margin-right: 1.6rem;

        &.btn_selected {
          background: var(--main-bg-green);
          color: var(--main-txt-white-color);
        }
      }
    }

    .filter_search {
      @include justify-center;
      position: relative;

      .catalog_search {
        @include dimensions(30rem, 3.2rem);

        .catalog_search_input {
          @include input-text;
          padding: 0.7rem 1.6rem;
        }
      }

      .search_icon {
        @include common-btn;
        position: absolute;
        right: 0;
        padding: 1rem;
        background: var(--main-bg-green);

        span {
          @include dimensions(1.8rem, 1.8rem);

          img {
            @include dimensions(100%, 100%);
          }
        }
      }
    }
  }

  .card_wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    height: calc(100vh - 20rem);
    padding: 2.2rem 0rem;
  }
}

.data_card_container {
  margin: 0rem 4rem 4rem 0rem;
  flex-basis: 22%;
  @include dimensions(auto, 28rem);
  cursor: pointer;
  border-radius: 0.4rem;
  background: var(--main-bg-white-smoke-grey-color);

  @media (max-width: 1490px) {
    flex-basis: 21%;
    @media (max-width: 1150px) {
      flex-basis: 20%;
    }
  }

  .card_images {
    display: block;
    width: 100%;
    height: 16.5rem;

    img {
      border-radius: 0.4rem;
      object-fit: cover;
      @include dimensions(100%, 100%);
    }
  }

  .dataCard_title_header {
    display: block;
    padding: 0.8rem 1.6rem;
    cursor: pointer;

    .dataCard_title {
      display: flex;
      justify-content: flex-start;
      @include font-profile-16-semibold;
      color: var(--main-txt-dark-grey);
      padding-bottom: 0.5rem;
    }

    .card_description {
      @include font-profile-14-normal;
      overflow: hidden;
      white-space: normal;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: var(--main-txt-dark-grey);
    }
  }
}
