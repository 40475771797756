@import '../../assets/styles/modules/mixins.scss';

.setting_header {
  @include align-center;
  padding: 2rem;

  span {
    @include dimensions(2rem, 2.5rem);
    cursor: pointer;

    img {
      @include dimensions(100%, 100%);
    }
  }

  label {
    @include font-profile-22-semibold;
    color: var(--main-txt-dark-charcoal-color);
    margin-left: 2rem;
  }
}

/* sidebar list */
.sidebar_tab_list {
  .sidebar_list {
    label {
      @include align-center;
      @include font-profile-16-semibold;
      color: var(--main-txt-dark-charcoal-color);
      height: 4.4rem;
      cursor: pointer;
      padding-left: 50px;
    }

    &:not(.active):hover {
      background: #c2ebca;
    }

    &.active {
      background: var(--main-bg-green-color);
      color: var(--main-txt-white-color);

      label {
        color: var(--main-txt-white-color);
      }
    }
  }
}
