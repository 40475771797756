@import '../../assets/styles/modules/mixins.scss';

.filter_label {
  @include font-profile-14-medium;
  color: var(--main-txt-sure-grey);
  margin-right: 2.6rem;
  white-space: nowrap;
}

/*user management main container*/

.user_manage_container {
  @include dimensions(100%, 100%);
  position: relative;
  background: var(--main-bg-light-grey-color);
  overflow: hidden;

  .user_manage_header {
    padding: 2.4rem 3.2rem 1.6rem 2.4rem;
    border-bottom: 0.1rem solid var(--main-bg-white-lite-color);

    h1 {
      @include font-profile-20-semibold;
      color: var(--main-txt-dark-grey);
      padding-bottom: 0.5rem;
    }

    .user_align_items {
      @include space-between;

      p {
        @include font-profile-14-normal;
        color: var(--main-txt-sure-grey);
      }

      .btns_container {
        display: flex;

        .import_csv_button {
          @include common-btn;
          background: var(--main-bg-white-color);
          color: var(--main-txt-green);
          margin-right: 1rem;
          border: 0.1rem solid var(--main-txt-green);
          padding: 0.5rem 1.4rem;
        }

        .add_new_button {
          @include common-btn;
          background: var(--main-txt-green);
          color: var(--main-txt-white-color);
          padding: 0.5rem 1.4rem;
        }

        span {
          @include dimensions(1.7rem, 1.4rem);
        }
      }
    }
  }
}

/*User search*/

.search_field_wrapper {
  @include space-between;
  padding: 1.6rem 3.2rem 1.6rem 2.4rem;

  .search_input_container {
    position: relative;

    .search_input {
      input {
        @include input-text;
        @include font-profile-14-normal;
        @include dimensions(36.1rem, 3.2rem);
        border-radius: 0.4rem;
        border: 0.1rem solid var(--main-border-white-smoke-color);
        background: var(--main-bg-white-color);
        padding: 0 3.2rem 0 3.2rem;
      }
    }

    .search_icon {
      @include dimensions(1.6rem, 1.6rem);
      position: absolute;
      top: 45%;
      left: 0.8rem;
      transform: translateY(-50%);
    }
  }

  .filter_options_container {
    @include align-center;
    margin-right: 4rem;

    .filter_button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin-right: 0.8rem;
      padding: 0.9rem 0.8rem;
      @include align-center;

      .arrow_icon_user {
        margin-left: 1.5rem;
        @include dimensions(1.2rem, 1.2rem);
        transform: rotate(90deg);
      }
    }

    .dropdown {
      .filter_dropdown_menu {
        @include direction_column;
        @include dimensions(13.2rem, auto);
        padding: 1rem;
        border-radius: 0.4rem;
        background: var(--Neutral-White, #fff);
        box-shadow: 0rem 0rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
        position: absolute;
        z-index: 999;

        li {
          text-wrap: nowrap;
          @include align-center;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }

        label {
          @include font-profile-14-medium;
          color: var(--main-txt-dark-grey);
          padding-left: 1rem;
        }

        input[type='checkbox'] {
          @include dimensions(1.6rem, 1.6rem);
          display: inline-flex;
          appearance: none;
          border: 0.1rem solid var(--main-border-sure-grey);
          border-radius: 0.3rem;
          cursor: pointer;
        }

        input[type='checkbox']:checked {
          @include dimensions(1.6rem, 1.6rem);
          display: inline-flex;
          border: 0.1rem solid var(--main-border-green);
          border-radius: 3px;
          background-color: var(--main-bg-green);
        }

        input[type='checkbox']:checked::after {
          display: block;
          content: '\2713';
          color: var(--main-txt-white-color);
          @include font-profile-14-normal;
          text-align: center;
          position: relative;
          left: 0.2rem;
          bottom: 0.3rem;
        }
      }
    }
  }
}

/*User Table*/

.user_table {
  display: inline-table;
  width: 100%;
  border-collapse: collapse;

  .user_table_body tr,
  .user_table_head {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .user_table_header {
    th {
      @include font-profile-14-semibold;
      background-color: var(--main-bg-white-smoke);
      padding: 1.3rem 2.4rem;
      text-align: left;
      color: var(--main-txt-sure-grey);
    }

    .padding_left {
      padding-left: 3.5rem !important;
    }

    .role_icon {
      margin: 0.3rem;
      vertical-align: middle;
      position: absolute;
    }

    .remove_icon_tooltip {
      @include font-profile-12-medium;
      display: none;
      position: absolute;
      bottom: calc(100% + 0.5rem);
      left: 50%;
      transform: translateX(-50%);
      padding: 0.4rem 0.8rem;
      border-radius: 0.4rem;
      background: rgba(0, 0, 0, 0.92);
      color: var(--main-txt-white-color);
      white-space: nowrap;

      &::before {
        content: '';
        position: absolute;
        bottom: -1rem;
        left: 50%;
        transform: translateX(-50%);
        border-width: 0.5rem;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.92) transparent transparent transparent;
      }
    }

    .role_icon:hover .remove_icon_tooltip {
      display: block;
    }
  }

  .user_table_body {
    display: block;
    position: inherit;
    overflow-y: scroll;
    height: calc(100vh - 35rem);
    border-bottom: 0.1rem solid var(--main-border-white-lite-color);

    .table_data {
      td {
        padding: 1.3rem 2.4rem;
        border-bottom: 1px solid var(--main-border-basic-grey-color);
        text-align: left;
        background: var(--main-bg-white-color);

        &.name {
          @include font-profile-14-semibold;
          color: var(--main-txt-dark-grey);
        }

        &.last_active {
          @include font-profile-14-medium;
          color: var(--main-txt-blue);
        }
      }

      .initial_main_block {
        display: flex;

        .initial_name {
          @include align-center;
          padding: 0.9rem 0rem;
        }
      }

      .role_name {
        cursor: pointer;
      }

      .arrow_icon_user {
        display: inline-table;
        margin: 0.5rem 1rem;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }

      .role_dropdown {
        position: absolute;
        background: var(--main-bg-white-color);
        box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.15);
        border-radius: 0.8rem;
        width: 10%;
        cursor: pointer;
        z-index: 9999;
        margin-top: 0.5rem;

        li {
          color: var(--main-txt-dark-grey);
          @include font-profile-14-medium;
          padding: 0.5rem 1rem 0.4rem 0.8rem;
        }
      }

      .switch {
        position: relative;
        display: inline-grid;
        @include dimensions(4rem, 2.3rem);

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 0.1rem solid var(--main-border-sure-grey);
          background-color: var(--main-bg-white-color);
          -webkit-transition: 0.4s;
          transition: 0.4s;

          &:before {
            @include dimensions(1.6rem, 1.6rem);
            position: absolute;
            content: '';
            left: 0.2rem;
            bottom: 0.26rem;
            background-color: var(--main-bg-sure-grey);
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
        }

        input:checked + .slider {
          background-color: var(--main-bg-green);
          border: 1px solid var(--main-border-green);

          &:before {
            -webkit-transform: translateX(1.8rem);
            -ms-transform: translateX(1.8rem);
            transform: translateX(1.8rem);
            background-color: var(--main-bg-white-color);
          }
        }

        .slider.round {
          border-radius: 3.4rem;

          &:before {
            border-radius: 50%;
          }
        }
      }

      .dropdown_container {
        &:focus {
          .dropdown_menu {
            display: block;
          }
        }

        span {
          margin-right: 10rem;
          cursor: pointer;
        }

        .dropdown_menu {
          position: absolute;
          display: none;
          right: 17.5rem;
          background-color: var(--main-bg-white-color);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          min-width: 16rem;
          z-index: 1;
          white-space: nowrap;
          bottom: 1.4rem;

          li {
            @include align-center;
            padding-left: 1rem;
            height: 3rem;
            cursor: pointer;

            &:hover {
              background: var(--main-bg-green);
              color: var(--main-txt-white-color) !important;

              img {
                filter: brightness(0) invert(1);
              }
            }
          }

          label {
            @include dimensions(2.4rem, 2.4rem);
            margin-right: 0.6rem;

            img {
              @include dimensions(100%, 100%);
            }
          }

          span {
            @include font-profile-14-semibold;
          }
        }
      }
    }

    .table_data_align {
      position: relative;
      text-align: center !important;
      background: var(--main-bg-white-color);
    }
  }

  [data-initials]:before {
    background: var(--main-bg-lite-grey-color);
    color: var(--main-txt-sure-grey);
    content: attr(data-initials);
    display: inline-block;
    font-weight: var(--main-font-weight-500);
    border-radius: 50%;
    margin-right: 0.5em;
    line-height: 4rem;
    text-align: center;
    @include dimensions(4rem, 4rem);
  }
}

.table_data_align {
  @include font-profile-14-semibold;
  background-color: var(--main-bg-white-smoke);
  text-align: center !important;
  color: var(--main-txt-sure-grey);
}

.user_remove_modal {
  padding: 1.6rem;

  .remove_modal_header {
    border-bottom: 0.1rem solid #f2f2f2;

    h2 {
      @include font-profile-16-medium;
      padding: 1.1rem;
      color: var(--main-txt-dark-grey);
    }
  }

  .remove_modal_body {
    @include space-between;
    @include font-profile-14-normal;
    padding: 2.4rem 0rem 3.2rem;
    border-bottom: 0.1rem solid #f2f2f2;

    p {
      color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
      padding-left: 1.6rem;
    }

    small {
      font-size: 1.4rem;
      color: var(--Syntax-Red);
    }
  }

  .remove_modal_footer {
    @include justify-end;
    padding: 1.4rem;

    .cancelButton {
      @include common-btn;
    }

    .removeButton {
      @include common-btn;
      border-radius: 4px;
      color: var(--main-txt-white-color);
      background: var(--Syntax-bg-Red);
    }
  }
}
