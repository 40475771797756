.head-tab {
  display: block;
  width: 100%;
}

.react-tabs__tab--selected {
  border: none;
  outline: none;
}

.request-container {
  display: block;
  width: 100%;
  background: #fff;
}

.request-header {
  display: flex;
  padding: 1.4rem 1.6rem 1.2rem 1.6rem;
  align-items: center;
  justify-content: space-between;
}

.request-header .image-wrapper {
  width: 1.6rem;
  height: 1.2rem;
  cursor: pointer;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
}

.request-heading {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-18);
  font-weight: var(--main-font-weight-500);
  padding-left: 1.4rem;
  display: flex;
  align-items: center;
}

.react-tabs {
  display: block;
  width: 100%;
}

.module-tab-list {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.2rem solid #f2f2f2;
  margin-top: 1rem;
}

.module-tab {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  padding: 0.4rem 3.6rem;
  text-align: center;
  font-weight: var(--main-font-weight-600);
  margin-right: 2%;
  cursor: pointer;
  color: var(--sat-sure-grey-sat-sure-grey-2, #696b72);
  font-size: var(--main-txt-size-14);
}

.headings {
  display: flex;
  align-items: center;
}

.headings_btn {
  margin-right: 3rem;
}

@media (max-width: 76.8rem) {
  .module-tab {
    width: 100%;
    margin-right: 0;
    font-size: var(--main-txt-size-14);
  }

  .module-tab-panel-container {
    padding: 0% 3% 1% 3%;
  }
}

@media (min-width: 76.8rem) and (max-width: 102.4rem) {
  .module-tab {
    margin-right: 1%;
    width: 11rem;
    font-size: var(--main-txt-size-16);
  }

  .module-tab-panel-container {
    padding: 0% 3% 2% 3%;
  }
}

.module-tab[aria-selected='true'] {
  position: relative;
  color: #3db267;
}

.module-tab[aria-selected='true']::after {
  content: '';
  position: absolute;
  bottom: -0.1rem;
  width: 100%;
  height: 0.2rem;
  background-color: #3db267;
}

.save-btn {
  display: flex;
  width: 12rem;
  padding: 0.6rem 0rem 0.7rem 0rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  background: var(--sparta-sparta-dark-1, #3db267);
  color: var(--neutral-white, #fff);
  text-align: center;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 1.6rem 1rem 1.6rem;
  border-bottom: 0.1rem solid #f2f2f2;
}

.changes-modal-container {
  width: 37.5rem;
  height: 17.5rem;
  border-radius: 0.8rem;
  background: #fff;
  box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.modal-header p {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  text-align: center;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.modal-header img {
  display: flex;
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
}

.changes-body {
  padding: 1.6rem;
}

.change-name label {
  color: var(--sat-sure-grey-sat-sure-grey-1, #42444a);
  font-size: var(--main-txt-size-14);
}

.common-modal-btn {
  display: flex;
  justify-content: center;
  padding-top: 2.4rem;
}

.color {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.save-button {
  background: #3db267;
  color: var(--main-txt-white-color);
  padding: 0.7rem 2.2rem 0.6rem 2.2rem;
}

.save-button.margin-right {
  margin-right: 2rem;
}

.discard-btn {
  border: 0.1rem solid var(--sparta-sparta-dark-1, #3db267);
  color: var(--sparta-sparta-dark-1, #3db267);
  background: #fff;
  padding: 0.7rem 1.2rem 0.7rem 1.2rem;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 9999;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem;
  row-gap: 2rem;
  column-gap: 2rem;
  overflow-y: scroll;
  margin-top: 1rem;
}

.project-wrapper {
  width: 31.5rem;
  height: 10.5rem;
  border: 0.1rem solid #c7c7cc;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.project-wrapper:hover {
  border: 0.1rem solid #3db267;
}

.project-header {
  color: #06776c;
  font-weight: var(--main-font-weight-700);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-20);
  margin-bottom: 2rem;
}

.project-requests {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-requests label,
.project-requests span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-requests label {
  margin-right: 1rem;
  color: #162564;
}

.project-requests span {
  width: 2rem;
  height: 2rem;
  background: #d7d7d7;
  border-radius: 0.3rem;
}

.detail-header-section {
  padding: 1.5rem 5.5rem 0rem 2rem;
  display: flex;
  flex-direction: inherit;
  height: 4rem;
  justify-content: left;
  border-bottom: 0.1rem solid #f2f2f2;
}

.detail-header {
  color: #42444a;
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-22);
}

.request-table {
  width: 100%;
  display: block;
  border-spacing: 0rem 0.4rem;
}

.table-body {
  display: block;
  position: relative;
  overflow-y: scroll;
  height: calc(100vh - 500px);
}

tr,
thead {
  text-align: center;
}

.table-body tr {
  height: 5rem;
}

.table-body tr,
.table-head {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.table-head tr {
  height: 4rem;
}

.project-list {
  margin: auto;
  width: 95%;
}

.project-list td {
  width: 16%;
  text-align: center;
  color: var(--main-txt-black-color);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.project-list th {
  padding: 1.4rem 0;
}

.project-list thead {
  background-color: var(--light-gray-bg);
}

.list-view-table {
  padding: 5rem 0 0 0;
}

.project-list-header th {
  width: 1%;
  text-align: center;
  padding: 1.4rem 0;
}

.project-list td:first-child {
  text-align: start;
}

.project-list-header thead {
  background-color: var(--light-gray-bg);
  display: table-caption;
}

tbody td {
  background: #f8f8f8;
}

thead th {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-20);
  color: #95969d;
}

tbody td label {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-20);
  color: #42444a;
  cursor: pointer;
}

.request-image {
  margin: 0rem 1rem 0rem 3rem;
  float: left;
}

.project-detail-wrapper {
  display: flex;
  flex-direction: column;
}

.project-user-title {
  display: flex;
  background: #f9f9f9;
  padding: 0.8rem 0.8rem 0.8rem 3rem;
  width: 100%;
  height: 34px;
  cursor: pointer;
  align-items: center;
}

.project-user-container h2 {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.project-user-detail {
  padding: 2rem 5.5rem;
}

.project-user-detail ul {
  display: flex;
  justify-content: space-between;
  column-gap: 10rem;
}

.project-user-detail ul li {
  display: flex;
  flex-direction: column;
  height: 5rem;
  justify-content: space-between;
  width: 200px;
}

.project-user-detail ul li label {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-19);
  color: #42444a;
}

.project-user-detail ul li span {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-19);
  color: #696b72;
}

.project_files {
  display: contents;
  color: var(--Sparta-Sparta-Dark-1, #3db267);
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.project_files a {
  padding-top: 3px;
}

#project-member-tooltip {
  position: relative;
}

#custom-tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
}

.full-name-avatar:hover {
  z-index: 10 !important;
}

.full-name-avatar:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  top: 117%;
  left: 50%;
  transform: translateX(-30%);
  padding: 5px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    #fff;
  color: #fff;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  font-size: 12px;
  z-index: 10 !important;
  overflow-wrap: break-word;
  height: auto;
  max-width: 270px;
  opacity: 1;
}

.full-name-avatar:not([data-tooltip]):hover::before {
  display: none;
}

.separate-div {
  display: flex;
  width: 222px;
  height: 176px;
  justify-content: center;
  align-items: center;
}

.avatar-clubbed:hover + .separate-div {
  display: flex;
  width: 222px;
  height: 176px;
  justify-content: center;
  align-items: center;
}

.project-members-data {
  display: flex;
  padding-top: 0.8rem;
}

.project-members-data img {
  width: 16%;
  cursor: pointer;
}

.project-owner span {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-16);
  color: #696b72;
}

.description-section label {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-19);
  letter-spacing: 0em;
  text-align: left;
  color: #42444a;
}

.description-section p {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-19);
  color: var(--main-txt--sat-lite-sure-grey);
}

.project-request-header {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-27);
  color: #42444a;
  padding-left: 10px;
}

.req-admin-col {
  display: flex;
  justify-content: center;
}

.req-admin-col img {
  height: 2rem;
  width: 2rem;
  display: flex;
  align-self: center;
}

.project-member-option-div {
  display: flex;
}
.project-member-option-details {
  display: flex;
  align-items: center;
}

.project-member-option-label {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.project-member-option-email {
  color: var(--SatSure-Grey-SatSure-Grey-2, #696b72);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.project-member-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  background-color: #e4e4e4 !important;
  border: none !important;
}

.project-member-avatar p {
  color: #727272;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Admin members dropdown */

.project-member-placeholder {
  text-align: justify;
}

.project-member-placeholder p,
.project-members__input-container {
  color: var(--main-txt-lite-grey);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-400);
}

.project-members__indicators {
  display: none !important;
}

.project-members__control--is-focused {
  border: 1px solid #3db267 !important;
  outline: none !important;
  box-shadow: none !important;
  min-height: 3.2rem !important;
}

.project-members__option {
  text-align: start;
  font-size: 12px !important;
}

.project-members__option--is-focused,
.project-members__option--is-selected {
  background-color: #3db267 !important;
  color: white !important;
}

.description-content {
  padding: 16px 72px 16px 24px;
  overflow-y: scroll;
}

.description-content p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Admin members dropdown */
.project-members__control {
  width: 25rem !important;
  min-height: 3.2rem !important;
}

.project-members__option--is-focused p,
.project-members__option--is-selected p {
  color: white;
}

.project-members__single-value {
  text-align: justify;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-400);
}

/* Add members/admins dropdown */
/* data product admin dropdown */
.project-admins__control {
  width: 22.2rem;
  height: 3.2rem;
  position: absolute !important;
  z-index: 5 !important;
}

.project-admins__indicators {
  display: none !important;
}

.project-admins__control--is-focused {
  border: 1px solid #3db267 !important;
  outline: none !important;
  box-shadow: none !important;
}

.project-admins__option {
  text-align: justify;
}

.project-admins__option--is-focused,
.project-admins__option--is-selected {
  background-color: #3db267 !important;
}

.project-admins__option--is-focused p,
.project-admins__option--is-selected p {
  color: white;
}

.project-admins__single-value {
  text-align: justify;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-400);
}

.project-admins__menu {
  display: inline-table;
  width: 22.2rem !important;
}

.project-members__menu-list {
  max-height: 150px !important;
  position: fixed !important;
  width: 25rem;
  z-index: 2;
  background: white;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
}
