/* variables */
:root {
  /* Text Color */
  --main-txt-white-grey-color: #c7c7cc;
  --main-txt-white-color: #ffffff;
  --main-txt-black-color: #000000;
  --main-txt-sure-grey: #696b72;
  --main-txt-blue: #2f80ed;
  --main-txt-lite-blue: #2f80ed;
  --main-txt-dark-grey: #42444a;
  --main-txt-dark-grey2: #444;
  --main-txt-green: #3db267;
  --main-txt-lite-grey: #95969d;
  --main-txt-green-color: #0db02b;
  --main-txt-lite-grey-color: #e0e0e0;
  --main-txt-dark-charcoal-color: #333333;
  --main-txt-dark-shade-gray-color: #020202;
  --main-txt-grey-color: #4f4f4f;
  --main-txt-mid-grey-color: #878787;
  --main-txt-zambezi-color: #606060;
  --main-txt-grey-dim-color: #707070;
  --main-txt-suva-grey-color: #8f8f8f;
  --main-txt-dull-navy-color: #262261;
  --secondary-txt-green: #66e066;
  --light-gray-bg: #fbfbfb;
  --Syntax-Red: #eb5757;
  --gray-bg: hwb(0 45% 55%);
  --main-txt-white-smoke: #f3f3f3;
  --main-txt-green-gray: #515d53;

  /* Bg Color */
  --main-bg-lite-grey-color: #eeeeee;
  --main-bg-light-grey-color: #fbfbfb;
  --main-bg-white-smoke-grey-color: #efefef;
  --main-bg-white-color: #ffffff;
  --main-bg-green-color: #0db02b;
  --main-bg-white-grey-color: #c7c7cc;
  --main-bg-white-lite-color: #f2f2f2;
  --main-bg-grey-color: #e0e0e0;
  --main-bg-white-brow-color: #f5f5f5;
  --main-bg-white-smoke: #f3f3f3;
  --main-bg-sure-grey: #696b72;
  --main-bg-satsure-black-grey: #252525e6;
  --main-bg-green: #3db267;
  --Syntax-bg-Red: #eb5757;

  /* Border Color */
  --main-border-blue-green-color: #108579;
  --main-border-green-color: #0db02b;
  --main-border-white-grey-color: #c7c7cc;
  --main-border-lite-grey-color: #e0e0e0;
  --main-border-grey-color: #828282;
  --main-border-red-color: #eb5757;
  --main-border-lite-grey: #95969d;
  --main-border-white-color: #ffffff;
  --main-border-whisper-color: #e4e4e4;
  --main-border-grey-lite-color: #bdbdbd;
  --main-border-white-smoke-color: #e9e9e9;
  --main-border-white-lite-color: #f2f2f2;
  --main-border-basic-grey-color: #ddd;
  --main-border-sure-grey: #696b72;
  --main-border-green: #3db267;

  /* Font Size */
  --main-txt-size-10: 1rem;
  --main-txt-size-12: 1.2rem;
  --main-txt-size-13: 1.3rem;
  --main-txt-size-14: 1.4rem;
  --main-txt-size-15: 1.5rem;
  --main-txt-size-16: 1.6rem;
  --main-txt-size-18: 1.8rem;
  --main-txt-size-20: 2rem;
  --main-txt-size-22: 2.2rem;
  --main-txt-size-24: 2.4rem;
  --main-txt-size-40: 4rem;
  --main-txt-size-65: 6.5rem;

  /*Font Family */
  --main-font-family-manrope: 'Manrope';
  --main-font-family-verdana: 'Verdana';
  --main-font-weight-400: 400;
  --main-font-weight-500: 500;
  --main-font-weight-600: 600;
  --main-font-weight-700: 700;
  --main-font-weight-800: 800;

  /* Line Height */
  --main-line-height-10: 1rem;
  --main-line-height-12: 1.2rem;
  --main-line-height-14: 1.4rem;
  --main-line-height-16: 1.6rem;
  --main-line-height-17: 1.7rem;
  --main-line-height-18: 1.8rem;
  --main-line-height-19: 1.9rem;
  --main-line-height-20: 2rem;
  --main-line-height-22: 2.2rem;
  --main-line-height-24: 2.4rem;
  --main-line-height-27: 2.7rem;
  --main-line-height-30: 3rem;
  --main-line-height-33: 3.3rem;
  --main-line-height-44: 4.4rem;
  --main-line-height-78: 7.8rem;
}
