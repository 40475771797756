/* Drafts  */

.drafts-container {
  width: 100%;
  height: calc(100vh - 61px);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drafts-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.drafts-wrapper h2 {
  color: var(--main-txt-dark-grey);
  font-size: var(--main-txt-size-24);
  line-height: var(--main-line-height-33);
  font-weight: 500;
  margin: 10px 0px;
}

.drafts-wrapper .empty-draft-sub-header {
  color: var(--main-txt-sure-grey);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-20);
}
